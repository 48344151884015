import Menu from './wifly/menu.json';
import MenuPhone from './wifly/menuPhone.json';

import Help from './wifly/help.json';
import Account from './wifly/account.json';

import Login from './wifly/login.json';
import Register from './wifly/register.json';
import Recovery from './wifly/recovery.json';
import RegisterCode from './wifly/register-code.json';
import RecoveryPassword from './wifly/recovery-password.json';

// Only admmin
import Country from './wifly/admin/country.json';
import Users from './wifly/admin/user.json';
import Supplier from './wifly/admin/supplier.json';
import Brand from './wifly/admin/brand.json';
import Zone from './wifly/admin/zone.json';
import Category from './wifly/admin/category.json';
import Product from './wifly/admin/product.json';
import ProductLine from './wifly/admin/product-line.json';
import Vehicle from './wifly/admin/vehicle.json';
import VehicleHistory from './wifly/admin/vehicle-history.json';
import ProductConfig from './wifly/admin/product-config.json';
import Contract from './wifly/admin/contract.json';
import Delivery from './wifly/admin/delivery.json';
import DeliveryLine from './wifly/admin/delivery-line.json';
import Battery from './wifly/admin/battery.json';
import Engine from './wifly/admin/engine.json';

// Admin
import Group from './wifly/admin/group.json';
import Permission from './wifly/admin/permission.json';
import Warehouse from './wifly/admin/warehouse.json';
import Rental from './wifly/admin/rental.json';
import Action from './wifly/admin/action.json';
import ActionComment from './wifly/admin/action-comment.json';
import ActionRental from './wifly/admin/action-rental.json';
import ActionConsumable from './wifly/admin/action-consumable.json';
import Consumable from './wifly/admin/consumable.json';
import ActionCategory from './wifly/admin/action-category.json';
import ActionPending from './wifly/admin/action-pending.json';
// Use this constructor file to create a new page, where we will embed power-BI or similar. It is very easy to embed power-BI

// Technical
import TRental from './wifly/technical/rental.json';
import TAction from './wifly/technical/action.json';
import TActionCategory from './wifly/technical/action-category.json';
import TActionComment from './wifly/technical/action-comment.json';
import TActionRental from './wifly/technical/action-rental.json';
import TActionConsumable from './wifly/technical/action-consumable.json';
import TActionPending from './wifly/technical/action-pending.json';
import TConsumable from './wifly/technical/consumable.json';
import TUsers from './wifly/technical/user.json';
import TProducts from './wifly/technical/product.json';
import TVehicles from './wifly/technical/vehicle.json';
import TZones from './wifly/technical/zone.json';
import TBattery from './wifly/technical/battery.json';
import TEngine from './wifly/technical/engine.json';

// Manager
import MGroup from './wifly/manager/group.json';
import MPermission from './wifly/manager/permission.json';
import MWarehouse from './wifly/manager/warehouse.json';
import MRental from './wifly/manager/rental.json';
import MAction from './wifly/manager/action.json';
import MActionRental from './wifly/manager/action-rental.json';
import MActionComment from './wifly/manager/action-comment.json';
import MActionConsumable from './wifly/manager/action-consumable.json';
import MConsumable from './wifly/manager/consumable.json';
import MInvitation from './wifly/manager/invitation.json';
// Flows
import MFRental from './wifly/manager/flows/register-rental.json';

export default function InkonskyConstructor() {

  return {
    type: 'frontend',
    name: 'professional',
    path: 'login',
    title: 'Ink on Sky',
    styles: {
      flex: 1,
      overflowY: {
        mobile: 'scroll',
      },
    },
    screens: [
      Login,
      MFRental,
      Recovery,
      Register,
      RegisterCode,
      RecoveryPassword,
      {
        type: 'screen',
        components: [
          {
            path: ['manager/*', 'admin/*', 'technical/*'],
            type: 'retrieve',
            endpoint: '/api/auth/valid',
            method: 'get',
            actions: {
              failed: [
                {
                  type: 'redirect',
                  path: '/',
                },
              ],
            },
            components: [
              {
                type: 'screen',
                name: 'calendar',
                path: ['manager/*', 'admin/*', 'technical/*'],
                styles: {
                  zIndex: 10,
                  display: 'flex',
                  flex: 1,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  flexDirection: 'row',
                },
                components: [
                  Menu,
                  MenuPhone,
                  {
                    type: 'screen',
                    name: 'unauthorized',
                    styles: {
                      display: 'flex',
                      flex: 1,
                      overflowY: 'scroll',
                    },
                    components: [
                      {
                        styles: {
                          display: {
                            mobile: 'block',
                            default: 'none',
                          },
                        },
                        type: 'space',
                      },
                      {
                        type: 'screen',
                        styles: {
                          display: 'flex',
                          flex: 1,

                          flexDirection: 'row',
                        },
                        components: [
                          {
                            type: 'screen',
                            name: 'unauthorized',

                            components: [
                              {
                                type: 'space',
                              },
                              {
                                path: '/manager/menu',
                                type: 'button',
                                icon: 'las la-arrow-left',
                                text: 'Volver',
                                styles: {
                                  display: {
                                    mobile: 'block',
                                    default: 'none',
                                  },
                                },
                              },
                              Battery,
                              Engine,
                              Country,
                              Users,
                              Supplier,
                              Brand,
                              Zone,
                              Category,
                              Product,
                              ProductLine,
                              Vehicle,
                              VehicleHistory,
                              ProductConfig,
                              Contract,
                              Delivery,
                              DeliveryLine,
                              Group,
                              Permission,
                              Warehouse,
                              Rental,
                              Action,
                              ActionCategory,
                              ActionComment,
                              ActionRental,
                              ActionConsumable,
                              ActionPending,
                              Consumable,
                              Help,
                              Account,
                              MGroup,
                              MPermission,
                              MWarehouse,
                              MRental,
                              MAction,
                              MActionRental,
                              MActionComment,
                              MActionConsumable,
                              MConsumable,
                              MInvitation,
                              TRental,
                              TAction,
                              TActionCategory,
                              TActionComment,
                              TActionRental,
                              TActionConsumable,
                              TActionPending,
                              TBattery,
                              TConsumable,
                              TEngine,
                              TProducts,
                              TUsers,
                              TVehicles,
                              TZones,
                              {
                                type: 'space',
                              },
                            ],
                          },
                          {
                            type: 'space',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

}
