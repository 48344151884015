import './index.scss';
import React, { useEffect } from 'react';
import Image from './image.component'
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import RequestAction from '../../../../actions/request/request.action';
import { useParams, useSearchParams } from 'react-router-dom';
import LayoutButtonComponent from '../../button'
import Conditional, { ConditionalType } from '../../../../utilities/conditional/conditional.utility';
import LayoutScreenComponent from '../../screen';
import LoaderComponent from '../../loader/loader.component';
import { useElementSize } from 'use-element-size'

import { useCallback, } from 'react'

interface DivisorComponentProps {
    title: string,
    description: string,
    icon?: string,
    styles?: any,
    bucket: string,
    empty: any,

    endpoint: string,
    buttons?: any,
    store: any,
    details: {
        components: any,
        conditions?: ConditionalType,

    }[]
}
function LayoutGalleryComponent(props: DivisorComponentProps) {

    const [items, setItems] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [imageSelected, setImageSelected] = React.useState<any>(null);
    const [page, setPage] = React.useState<number>(1);
    const [search, setSearch] = React.useState<string>('');
    const [count, setCount] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(0);
    const [width, setWidth] = React.useState<number>(0);
    const location = useParams();
    const [searchParams] = useSearchParams();
    const [cache, setCache] = React.useState<string>('');
    const ref = useElementSize((size, prevSize, elem) => {

        if (size) {

            let width = size.width;
            // Phone:
            const gap = 2
            if (width < 600) {

                width = (width - gap) / 2

            }
            // Tablet:
            if (width >= 600 && width < 1024) {

                width = (width - (gap * 2)) / 3

            }

            // Desktop:
            if (width >= 1024) {

                width = (width - (gap * 3)) / 4

            }
            setWidth(width)

        }

    })
    const getData = (page: number) => {

        setLoading(true)
        setPage(page)
        setItems([])
        if (!props.endpoint) return false;
        const currentUserToken = getProperty(props.store, 'user.access_token');

        let path = `${props.endpoint}?page=${page}`;
        if (search) {

            // Replace page from serach:

            path = `${path}&search=${search}`

        }

        RequestAction({
            endpoint: path,
            method: 'get',
            headers: {
                'authorization': 'Bearer ' + currentUserToken
            }
        }).then((response: any) => {

            setTimeout(() => {

                setLoading(false)
                setItems(response.data.items)
                setCount(response.data.count)
                setLimit(response.data.limit)

            }, 300);

        }).catch((error) => {

            setLoading(false)

        })
        return true;

    }

    useEffect(() => {

        getData(0);

    }, [])

    useEffect(() => {

        const queryCache = searchParams.get('cache');
        if (queryCache && queryCache !== cache) {

            setCache(queryCache);
            getData(0)

        }

    }, [searchParams])
    if (loading === false && items.length === 0 && props.empty) {

        return <LayoutScreenComponent components={props?.empty} />

    }
    return (

        <div className='LayoutGalleryComponent' style={props.styles} ref={ref}>
            {
                loading && <LoaderComponent status={loading} />
            }
            <div className='items'>
                {
                    items?.map((item: any, index: number) => {

                        return (
                            <div className='item' key={index} onClick={
                                () => {

                                    setImageSelected(item);

                                }
                            } style={{
                                width: width,
                                height: width,
                                backgroundImage:
                                    `url(${props.bucket}${item.image})`
                            }}>
                                <div className='image' style={{
                                    width: width,
                                    height: width,
                                    backgroundImage:
                                        `url(${props.bucket}${item.image})`
                                }} />
                                <i className='icon las la-expand' />
                                <div className='details'>
                                    {
                                        props.details?.map((detail: any, index: number) => {

                                            if (Conditional(detail.conditions, item)) {

                                                return <LayoutScreenComponent components={detail.components} item={item} />

                                            }
                                            else {

                                                return null

                                            }

                                        })
                                    }
                                </div>
                                <div className='buttons'>
                                    {
                                        props.buttons?.map((button: any, index: number) => {

                                            return (
                                                <LayoutButtonComponent item={item} icon={button.icon} actions={button.actions} />)

                                        })
                                    }
                                </div>
                            </div>
                        );

                    })
                }
            </div>
            {
                // paginator
                !loading && count > (page + 1) * limit && <div className='paginator'>
                    < div className='more'>

                        {page > 0 && <i className='icon las la-arrow-left' onClick={
                            () => {

                                getData(page - 1)

                            }}
                        />}
                        <i className='icon las la-arrow-right' onClick={
                            () => {

                                getData(page + 1)

                            }}
                        />
                    </div>
                </div >
            }
            {
                imageSelected &&
                <Image
                    path={`${props.bucket}${imageSelected.image}`}
                    onClose={
                        () => {

                            setImageSelected(null);

                        }
                    } />

            }
        </div >

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(LayoutGalleryComponent)
