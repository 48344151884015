/* eslint-disable */
import ExecuteActions from '../../../actions';
import { useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from '../markdown'
import './index.scss';
interface LayoutSplashProps {
    setRoute: any,
    mask?: string,
    title: string,
    subtitle: string,
    slogan?: string,
    description: string,
    video: string,
    buttons: { text: string, actions: any }[]
}
function LayoutArticleComponent(props: LayoutSplashProps) {
    const locationParams = useParams();
    console.log('params:', locationParams)

    const navigation = useNavigate()
    return (

        <div className='LayoutArticleComponent'>
            {
                props.mask && <div className='mask' style={{ backgroundColor: `${props.mask}` }} />
            }
            <div className='content'>
                {props.slogan && <div className='slogan'>
                    <ReactMarkdown>{props.slogan}</ReactMarkdown>
                </div>}
                <div className='title'>
                    <ReactMarkdown >{props.title}</ReactMarkdown>
                </div>
                <div className='subtitle'>
                    <ReactMarkdown>{props.subtitle}</ReactMarkdown>
                </div>
                <div className='description'>
                    <ReactMarkdown>{props.description}</ReactMarkdown>
                </div>
                <div className='buttons'>
                    {props.buttons && props.buttons.map((button, index) => {
                        return (
                            <button key={index} onClick={() => {
                                ExecuteActions({
                                    params: locationParams,
                                    commands: {
                                        navigation: navigation,

                                    }, actions: button.actions
                                })
                            }} type='button' className='TitleComponentButton'>
                                {button.text}
                                <i className='las la-arrow-right' />
                            </button>
                        );
                    })}
                </div>
            </div>

        </div>


    );
}
export default LayoutArticleComponent
