import {
  CLEAR,
  SET_ROUTE,
  SET_SCREEN,
  SET_USER,
  SET_STORE,
} from '../constants/actions';

export function setUser(payload) {

  return {
    type: SET_USER,
    payload,
  };

}

export function setStore(payload) {

  return {
    type: SET_STORE,
    payload,
  };

}
export function setRoute(payload) {

  return {
    type: SET_ROUTE,
    payload,
  };

}
export function setScreen(payload) {

  return {
    type: SET_SCREEN,
    payload,
  };

}

export function clear(payload) {

  return {
    type: CLEAR,
    payload,
  };

}
