import React from "react";

const Sides = (props: any) => {

    return (
        <div className="sides">

            {['right', 'left'].map(side => (
                <button
                    className={`side-${side}`}

                />
            ))}
            <div className='modal' onClick={
                () => {

                    props.onClose();

                }
            }>
                <img alt='' src={`${props.path}`} />
            </div>
        </div>);

};

export default (Sides);
