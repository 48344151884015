import './object.styles.scss'
export default function ObjectComponent(props: { data: any }) {

    return <div className="ObjectComponent">
        {
            Object && props.data && Object.keys(props.data).map((key, index) => {

                return <div className='item' key={index}>
                    <div className='key'>{key}</div>
                    <div className='value'>{
                        typeof props.data[key] === 'boolean' ? <span>{props.data[key] ? 'true' : 'false'}</span> :
                            'any'}</div>
                    <div className='type'>
                        {typeof props.data[key]}
                    </div>
                </div>

            })
        }
    </div>

}