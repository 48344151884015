import EndpointComponent from '../../development/endpoint/endpoint.component';
import VariableComponent from '../../development/variable/variable.component';
import ButtonWiflyComponent from '../button/button.wifly.component';
import { environment } from '../../../../scheme/environments/environment';
import './table.wifly.style.scss';
import RequestAction from '../../../actions/request/request.action';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { createPath, useParams, useSearchParams } from 'react-router-dom';
import LoaderComponent from '../../layout/loader/loader.component';
import Stylize from '../../../stylize';
import 'moment/locale/es';
import { CSVLink } from "react-csv";
import {
  WiflyLayoutTableProps,
  ActiveFilterValue,
  getIdFromKey,
  getValueFromKey,
  renderColum,
  createEndPoint,
  getValueFromPath,
  appendFilterToPath,
  orderByToPath,
  normalizeString,
  createPathData,
  downloadCSV,
  getValueFromKeyFilterDisplay,
} from './utils.table.wifly';
import { count } from 'console';

function TableWiflyComponent(props: WiflyLayoutTableProps) {
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [maxCount, setMaxCount] = useState(-1);
  const [page, setPage] = useState(0);
  const [cache, setCache] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [pathItem, setPathItem] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<{
    [key: string]: ActiveFilterValue;
  }>({});
  const [activeOrders, setActiveOrders] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [limit, setLimit] = useState(0);
  const location = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [filtersToOr, setfiltersToOr] = useState(true);

  const onApplyFilters = (key: string, values: any[]) => {
    setActiveFilters((prev: { [key: string]: ActiveFilterValue }) => {
      if (values.length === 0 || values.every((item) => item.id === 0)) {
        const { [key]: _, ...rest } = prev;
        return rest;
      }
      const currentUseOr = prev[key] ? prev[key].useOr : true;
      const uniqueIds = new Set(values.map((item) => item.id));
      const uniqueValues = Array.from(uniqueIds).map((id) => {
        return values.find((item) => item.id === id);
      });
      const updatedFilters = {
        ...prev,
        [key]: { values: uniqueValues, useOr: currentUseOr },
      };
      return updatedFilters;
    });
  };
  const removeFilter = (key: string, filterToRemove: any) => {
    setActiveFilters((currentFilters: { [key: string]: ActiveFilterValue }) => {
      const updatedFilters = { ...currentFilters };
      if (updatedFilters[key]) {
        updatedFilters[key] = {
          ...updatedFilters[key],
          values: updatedFilters[key].values.filter(
            (item: any) => item.id !== filterToRemove.id
          ),
        };
        if (updatedFilters[key].values.length === 0) {
          delete updatedFilters[key];
        }
      }
      return updatedFilters;
    });
  };
  const removeKeyFilter = (key: string) => {
    setActiveFilters((currentFilters) => {
      const updatedFilters = { ...currentFilters };
      delete updatedFilters[key];
      return updatedFilters;
    });
  };
  const clearAllActiveFilters = () => {
    setActiveFilters({});
  };
  const toggleFilterBehavior = (key: string) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        ...prevFilters[key],
        useOr: !prevFilters[key].useOr,
      },
    }));
  };
  const handleDownloadCSV = (() => {
    let path = createPathData(createEndPoint(props.endpoint, location), search, page, activeOrders, activeFilters, location, props)
    path += `&limit=${count}`;
    RequestAction({endpoint: path , method: props.method,
      headers: {
        authorization: 'Bearer ' + getProperty(props.store, 'user.access_token'),
      }}).then((response: any) => {
        setTimeout(() => {
          downloadCSV(response.data.items, props.columns)
        }, 300);
      }).catch((error) => {
      })
  });
  const onApplyOrder = (key: string, value: string) => {
    setActiveOrders((prevOrders) => {
      if (value === '') {
        const { [key]: removed, ...rest } = prevOrders;
        return { ...rest };
      }
      return { ...prevOrders, [key]: [value] };
    });
  };
  const removeOrder = (key: string, value: string) => {
    setActiveOrders((currentOrders) => {
      const updatedOrders = { ...currentOrders };
      delete updatedOrders[key];
      return updatedOrders;
    });
  };
  const removeAllOrders = () => {
    setActiveOrders({});
  };
  const toggleOrderDirection = (key: string) => {
    setActiveOrders((prevOrders) => {
      const currentDirection = prevOrders[key]?.[0];
      const newDirection = currentDirection === 'ASC' ? 'DESC' : 'ASC';
      return { ...prevOrders, [key]: [newDirection] };
    });
  };
  //Get data with including HTTP Request
  const getData = (page: number, searchDirect = search) => {
    //Start loading and SetPage to desired page
    setLoading(true);
    setPage(page);
    if (!props.endpoint) return false;
    //Get Access Token
    //TODO: move from props.store to access React.Redux "connect" directly
    const currentUserToken = getProperty(props.store, 'user.access_token');
    //create endpoint && path
    let endpoint = createEndPoint(props.endpoint, location);
    let path = createPathData(endpoint, searchDirect, page, activeOrders, activeFilters, location, props);
    //set path to useState
    setPathItem(path);
    //HTTP Request
    RequestAction({
      endpoint: path,
      method: props.method,
      headers: {
        authorization: 'Bearer ' + currentUserToken,
      },
    })
      .then((response: any) => {
        setTimeout(() => {
          setLoading(false);
          setItems(response.data.items);
          setCount(response.data.count);
          setLimit(response.data.limit);
          if (maxCount === -1) setMaxCount(response.data.count);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
      });
    return true;
  };

  const renderColumnHeader = () => {
    if (items && props.header)
      return (
        <div className="head">
          {props.columns.map((column, index) => {
            if (column.type === 'space')
              return <div key={index} className="space" />;
            return (
              <Stylize
                className="column"
                key={index}
                style={{
                  ...column.styles,
                  minWidth: column.width,
                  maxWidth: column.width,
                  width: column.width,
                }}
              >
                {column.header && column.header.icon ? (
                  <div className="icon">
                    <i className={`  ${column.header.icon}`} />
                  </div>
                ) : null}
                {column.type === 'button' && !column.title && (
                  <div className="button">
                    <div className={`icon ${column.icon}`} />
                  </div>
                )}
                {column.type === 'image' && (
                  <div className="image">
                    <div className={`image ${column.icon}`} />
                  </div>
                )}
                {(column.type === 'filter' || column.type === 'buttonFilter')&& (
                  <div className="filter">
                    <ButtonWiflyComponent
                      path={column.path === undefined ? props.endpoint : column.path}
                      type={column.type}
                      text={column.title}
                      tableItems={column.type === 'buttonFilter' ? undefined : undefined}
                      label={column.label}
                      content={column.content}
                      distinctSearchId={column.distinctSearchId}
                      id={column.id}
                      count={maxCount}
                      filterType={column.filterType}
                      onApplyFilters={onApplyFilters}
                      onApplyOrder={onApplyOrder}
                      activeFilters={
                        activeFilters && activeFilters[column.title]
                          ? activeFilters[column.title].values
                          : undefined
                      }
                      activeOrder={
                        activeOrders && activeOrders[column.title]
                          ? activeOrders[column.title][0]
                          : undefined
                      }
                    />
                  </div>
                )}
                {(column.type === undefined ||
                  (column.type === 'button' && column.title) ||
                  column.type === 'status' ||
                  column.type === 'text' ||
                  column.type === 'date' ||
                  column.type === 'number') && (
                  <div className="text">{column.title}</div>
                )}
                {column.type === 'price' && (
                  <div className="price">{column.title}</div>
                )}
                {environment.debug && column?.id && (
                  <VariableComponent name={column?.id} />
                )}
              </Stylize>
            );
          })}
        </div>
      );
  };
  const renderFilters = () => {};
  //Reload data when using search bar
  useEffect(() => {
    const queryCache = searchParams.get('cache');
    if (queryCache && queryCache !== cache) {
      setCache(queryCache);
      getData(0);
    }
  }, [searchParams]);

  //Reload data on Authentication change
  //TODO: change from props.store to access React-Redux directly
  useEffect(() => {
    getData(0);
  }, [activeFilters, filtersToOr, activeOrders, props.store]);

  //Return true if items is empty
  const empty = !items || items.length === 0;

  //Component html body
  return (
    <div className="TableWiflyComponent">
      {/*ACTIVE ORDERS*/}
      <div className="selected-orders flex flex-wrap gap-2 py-1">
        {Object.keys(activeOrders).length > 0 && (
          <button
            onClick={removeAllOrders}
            className="bg-gray-300 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-l focus:outline-none focus:shadow-outline"
          >
            x
          </button>
        )}
        {Object.entries(activeOrders).map(([key, values]) =>
          values.map((value, index) => (
            <div
              key={`${key}-${index}`}
              className="bg-green-100 text-green-800 text-sm py-1 px-3 rounded flex items-center justify-between"
            >
              <span
                className="cursor-pointer hover:text-green-500"
                onClick={() => toggleOrderDirection(key)}
              >
                {`${key}: ${value}`}
                {value === 'ASC' && <span className="text-xs ml-2">↑</span>}
                {value === 'DESC' && <span className="text-xs ml-2">↓</span>}
              </span>
              <button
                onClick={() => removeOrder(key, value)}
                className="ml-3 rounded-full p-0.5 inline-flex items-center justify-center text-green-500 hover:text-red-500 focus:outline-none"
              >
                &times;
              </button>
            </div>
          ))
        )}
      </div>

      {/*ACTIVE FILTERS*/}
      <div className="selected-filters flex flex-col gap-2">
        {Object.keys(activeFilters).map((key) => (
          <div key={key} className="flex flex-col">
            <div className="flex flex-row flex-wrap gap-2">
              <button
                onClick={() => removeKeyFilter(key)}
                className="bg-gray-300 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-l focus:outline-none focus:shadow-outline"
              >
                x
              </button>
              <div
                onClick={() => toggleFilterBehavior(key)}
                className={`ml-1 w-16 h-8 flex items-center rounded-lg p-1 cursor-pointer ${
                  activeFilters[key].useOr
                    ? ' bg-gray-300 justify-start'
                    : 'bg-blue-500 justify-end'
                }`}
              >
                <div
                  className={`w-8 h-6 bg-white rounded-lg shadow-md flex items-center justify-center ${
                    activeFilters[key].useOr ? 'translate-x-2' : 'translate-x-0'
                  }`}
                >
                  {activeFilters[key].useOr ? 'OR' : 'AND'}
                </div>
              </div>
              {activeFilters[key].values.map((item: any, index: number) => {
                let display = getValueFromKeyFilterDisplay(key, item, props.columns);
                const column = props.columns.find(
                  (column: any) => column.title === key
                );
                if (column) {
                  const content = column.content || undefined;
                  if (content && content.length > 0) {
                    if (content[0].type && content[0].type === 'boolean') {
                      display = display ? '✓' : 'x';
                    } else if (content[0].conditions && content[0].conditions[0]) {
                      display = display === null ? content[0].text : display;
                    }
                  }
                }
                return (
                  <div
                    key={`${key}-${index}`}
                    className="bg-blue-100 text-blue-800 text-m py-1 px-3 rounded flex items-center justify-between"
                  >
                    {`${key}: ${display}`}
                    <button
                      onClick={() => removeFilter(key, item)}
                      className="ml-2 rounded-full p-0.5 inline-flex items-center justify-center text-blue-500 hover:text-red-500 focus:outline-none"
                    >
                      &times;
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      {Object.values(activeFilters).some(
        (filter) => filter.values.length > 0
      ) && (
        <div className="flex justify-start mb-4 mt-4 items-center">
          <button
            onClick={clearAllActiveFilters}
            className="ml-3 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded-lg focus:outline-none focus:shadow-outline transition-colors duration-300"
          >
            Clear Filters
          </button>
        </div>
      )}
      {/*SEARCH BAR*/}
      <LoaderComponent status={loading} />
      {!loading && (
        <>
          {environment.debug && (
            <EndpointComponent
              endpoint={props.endpoint}
              method={props.method}
            />
          )}
          {props.search !== false && (
            <div className="search">
              <i className="icon las la-search" />
              <input
                value={search}
                type={'text'}
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                autoComplete="off"
                name="search"
                onChange={(e) => {
                  const value = e.target.value;
                  setSearch(normalizeString(value));
                }}
                placeholder={`Buscar...`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getData(0);
                  }
                }}
              />
              {search && (
                <i
                  className="search-close las la-times"
                  onClick={() => {
                    setSearch('');
                    getData(0, '');
                  }}
                />
              )}
              {search && (
                <i
                  className="search-submit las la-arrow-right"
                  onClick={() => {
                    getData(0);
                  }}
                />
              )}
              {environment.debug && <VariableComponent name={'search'} />}
            </div>
          )}
          {/*CSV DOWNLOAD*/}
          {<div>
            <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold my-2 mx-1 py-1 px-4 rounded"
            onClick={() => handleDownloadCSV()}>Download CSV</button>  
          </div>}
          {empty && (
            <div className="scroll" style={{ minHeight: '650px' }}>
              {/*COLUMN HEADERS*/}
              {renderColumnHeader()}
              <div className="empty">
                <b>SIN RESULTADOS</b>
              </div>
            </div>
          )}
          {/* SEARCH WINDOW WITH RESULTS */}
          {!empty && (
            <div className="scroll" style={{ minHeight: '650px' }}>
              {/*COLUMN HEADERS*/}
              {renderColumnHeader()}
              {/*COLUMN CONTENT*/}
              <div className="body">
                {items.map((item: any, rowIndex) => {
                  return (
                    <div className="row" key={rowIndex}>
                      {props.columns.map((column, indexr) => {
                        if (column.type === 'space')
                          return (
                            <div
                              key={`${rowIndex}-${indexr}`}
                              className="space"
                            />
                          );
                        return renderColum(
                          `${rowIndex}-${indexr}`,
                          item,
                          column
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/*PAGINATION*/}
          {limit && (
            <div className="pagination">
              <div className="count">
                <b>{count} resultados </b>{' '}
              </div>
              <div className="controls">
                {page > 0 && (
                  <div
                    className="button back"
                    onClick={() => {
                      getData(page - 1);
                    }}
                  >
                    <i className="icon las la-angle-left" />
                  </div>
                )}
                {count / limit - 1 > page && (
                  <div
                    className="button next"
                    onClick={() => {
                      getData(page + 1);
                    }}
                  >
                    <i className="icon las la-angle-right" />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {/*DEBUG*/}
      {environment.debug && (
        <EndpointComponent
          endpoint={`${count} elements with limit ${limit}`}
          method={`${page} current page`}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state: any, ownProps: any) => state;

export default connect(mapStateToProps)(TableWiflyComponent);
