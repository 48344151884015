import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Replace } from '../../../utilities/replace/replace.utility';
import './index.scss';
interface DivisorComponentProps {

    styles?: any
}
function LayoutBackgroundComponent(props: DivisorComponentProps) {

    return (

        <div className='LayoutBackgroundComponent' style={props.styles}>

        </div>

    );

}
export default LayoutBackgroundComponent
