import moment from 'moment';
import Conditional from '../../../utilities/conditional/conditional.utility';
import { Replace } from '../../../utilities/replace/replace.utility';
import React, { useMemo } from 'react';
import { getProperty } from 'dot-prop';
import Stylize from '../../../stylize';
import LayoutButtonComponent from '../../layout/button';
import Papa from 'papaparse';
import { CSVDownload, CSVLink } from 'react-csv';
import RequestAction from '../../../actions/request/request.action';

export interface WiflyLayoutTableColumnType {
  id: string;
  title: string;
  icon?: string;
  decimals?: number;
  label?: string;
  distinctSearchId?: string;
  buttonFilterId?: string;
  color?: string;
  format?: string;
  header?: {
    icon?: string;
  };
  text?: string;
  styles?: any;
  tooltip?: string;
  content?: WiflyLayoutTableColumnType[];
  conditions?: any;
  actions?: any;
  type:
    | 'status'
    | 'mark'
    | 'space'
    | 'icon'
    | 'button'
    | 'text'
    | 'price'
    | 'image'
    | 'filter'
    | 'buttonFilter'
    | 'date'
    | 'number'
    | 'boolean';
  width?: number;
  path?: string;
  filterType?: 'search' | 'order' | 'searchOrder';
}
export interface WiflyLayoutTableProps {
  columns: WiflyLayoutTableColumnType[];
  results?: number;
  data?: any[];
  store: any;
  query?: {
    [key: string]: {
      optional: boolean;
    };
  };
  header: boolean;
  endpoint: string;
  method: string;
  search?:
    | boolean
    | {
        placeholder?: string;
      };
  path?: string;
}

export interface ActiveFilterValue {
  values: any[];
  useOr: boolean;
}

//Validation
export const validationConditionals = (
  item: any,
  column: WiflyLayoutTableColumnType
) => {
  let render = true;
  if (column.conditions) {
    render = Conditional(column.conditions, item);
  }
  return render;
};

//Render Data
export const renderDateNice = (value: any) => {
  const tz = moment.tz.guess();
  const date = moment(value).tz(tz);
  return date.format('DD/MM/YYYY HH:mm');
};

export const renderDateFromNow = (value: any) => {
  moment.locale('es');

  const tz = moment.tz.guess();
  const date = moment(value).tz(tz);
  date.locale('es');
  return date.fromNow();
};

export const renderNumber = (
  column: WiflyLayoutTableColumnType,
  value: any
) => {
  if (column.decimals) {
    return value.toFixed(column.decimals);
  }
  return value;
};

export const renderContent = (
  column: WiflyLayoutTableColumnType,
  item: any
) => {
  return (
    <>
      {column.content && typeof column.content === 'string'
        ? Replace({ item }, column.content)
        : column.content &&
          column.content.map((content: any, index: number) => {
            const val = validationConditionals(item, content);
            if (val) {
              return (
                <React.Fragment key={index}>
                  {renderData(content, item)}
                </React.Fragment>
              );
            }
            return null;
          })}
    </>
  );
};

export const renderData = (
  column: WiflyLayoutTableColumnType,
  item: any
): any => {
  let value: any = getProperty(item, column?.id);
  if (!value && typeof value !== 'number') {
    value = getProperty({ item }, `${column?.id}`);
  }

  return (
    <>
      {!column.content && (column.type === 'button' || column.type === 'buttonFilter') && (
        <LayoutButtonComponent
          item={item}
          icon={column.icon}
          actions={column.actions}
          tooltip={column.tooltip}
        />
      )}

      {column.content && (column.type === 'button' || column.type === 'buttonFilter') && (
        <LayoutButtonComponent
          item={item}
          icon={column.icon}
          actions={column.actions}
          tooltip={column.tooltip}
          children={renderContent(column, item)}
        />
      )}

      {column.type === 'icon' && (
        <div className={`table-icon ${column.icon}`} />
      )}

      {column.type === 'image' && (
        <div
          className="image"
          style={{
            backgroundImage: `url(/graphics/images/cosmetics/table/1.jpg)`,
          }}
        >
          <div className={`image ${column.icon}`} />
        </div>
      )}

      {column.type === 'mark' && (
        <div className="mark">
          <Stylize className={`mark-box`} style={column.styles}>
            {typeof column.color === 'string' && (
              <div
                className="mark-color"
                style={{ backgroundColor: column.color }}
              />
            )}

            {typeof value === 'string' ? value : ''}
            {typeof column.text === 'string'
              ? Replace({ item: item }, column.text)
              : ''}
          </Stylize>
        </div>
      )}

      {column.type === 'status' && (
        <div className="status">
          <div
            className={`status-box 
                ${typeof value === 'boolean' && value === true && 'statusGreen'}
                ${typeof value === 'boolean' && value === false && 'statusRed'}
                ${
                  typeof value === 'string' &&
                  value === 'active' &&
                  'statusGreen'
                }
                ${
                  typeof value === 'string' &&
                  value === 'desactive' &&
                  'statusRed'
                }
                ${
                  typeof value === 'string' &&
                  value === 'finish' &&
                  'statusGrey'
                }
              `}
          >
            {typeof value === 'string' ? value : ''}
          </div>
        </div>
      )}

      {column.type === 'text' && (
        <div className="text">
          {typeof value === 'string' ? value : ''}
          {typeof column.text === 'string'
            ? Replace({ item: item }, column.text)
            : ''}
        </div>
      )}

      {column.type === 'number' && (
        <div className="text">
          {typeof value === 'number' && renderNumber(column, value)}
        </div>
      )}

      {column.type === 'boolean' && (
        <div className="icon">
          {typeof value === 'boolean' && value ? (
            <i className=" las la-check" />
          ) : (
            <i className=" las la-times" />
          )}
        </div>
      )}

      {column.format && column.type === 'date' && (
        <div
          className="text"
          data-tip={
            column.tooltip &&
            Replace(
              { item: { ...item, render: renderDateNice(value) } },
              column.tooltip
            )
          }
        >
          {typeof value === 'string' ? renderDateNice(value) : ''}
        </div>
      )}

      {!column.format && column.type === 'date' && (
        <div
          className="text"
          data-tip={
            column.tooltip &&
            Replace(
              { item: { ...item, render: renderDateNice(value) } },
              column.tooltip
            )
          }
        >
          {renderDateFromNow(value)}
        </div>
      )}

      {column.type === 'price' && (
        <div className="price">
          <b>{typeof value === 'number' ? value.toFixed(2) : value}</b>
        </div>
      )}

      {column.content && column.type !== 'button' && (
        <div className="content">{renderContent(column, item)}</div>
      )}
    </>
  );
};

export const normalizeString = (str: string): string => {
  return str
    .normalize('NFD') // Normalize to decomposed form
    .replace(/[\u0300-\u036f]/g, ''); // Remove combining diacritical marks
};

export const renderColum = (
  index: string,
  item: any,
  column: WiflyLayoutTableColumnType
) => {
  let condition = true;
  if (column.conditions) condition = validationConditionals(item, column);
  if (condition === false) return null;

  return (
    <Stylize
      className="column"
      key={`${index}-sd`}
      style={{
        ...column.styles,
        minWidth: column.width,
        maxWidth: column.width,
        width: column.width,
      }}
    >
      {renderData(column, item)}
    </Stylize>
  );
};

export const getValueFromPath = (obj: any, path: string | string[]) => {
  if (!Array.isArray(path)) {
    path = path.replace(/{|item\.|}/g, '');
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  let result: string[] = [];
  path.forEach((val: string) => {
    result.push(Replace({ item: obj }, val) + ' ');
  });
  return result;
};

export const getValueFromKey = (key: string, value: any, columns: any) => {
  const column = columns.find((column: any) => column.title === key);
  let display = column ? column.label : key;
  if (column.type === 'buttonFilter' && column.buttonFilterId){
    display = column.buttonFilterId
  }
  return getValueFromPath(value, display || key);
};

export const getValueFromKeyFilterDisplay = (key: string, value: any, columns: any) => {
  const column = columns.find((column: any) => column.title === key);
  let display = column ? column.label : key;
  return getValueFromPath(value, display || key);
};
export const getIdFromKey = (key: string, columns: any): string[] => {
  const column = columns.find((col: any) => col.title === key);
  if (column && column.type != 'buttonFilter' && column.content && column.content.length > 0) {
    if (column.content.length > 1) {
      let keys: string[] = [];
      column.content.forEach((item: any) => {
        if (item.id != undefined) {
          keys.push(item.id);
        }
      });
      return keys;
    }
    return [column.content[0].id];
  } else if (column && column.type === 'buttonFilter'){
    let keys: string[] = [];
    keys.push('id');
    return keys;
  }
  return [];
};

export const createEndPoint = (endPoint: any, location: any): string => {
  //Checks and appends if path has any id (which is stored in React Router)
  //If so then append all location (ids)
  let endpoint: string = endPoint;
  endpoint = location['id'] ? endpoint.replace('{id}', location['id']) : endpoint;
  Object.keys(location).forEach((key) => {
    const value = location[key];
    if (value) endpoint = endpoint.replace(`{param.${key}}`, value);
  });
  // Adding ? to Endpoint for queries if ? not in endpoint
  if (endpoint.indexOf('?') === -1) endpoint = `${endpoint}?`;
  return endpoint;
};

export const createPathData = (
  endpoint: string,
  searchDirect: string,
  page: any,
  activeOrders: any,
  activeFilters: any,
  location: any,
  props: any
): string => {
  //Create path from endpoint && adding page for pagination purposes
  let path = appendFilterToPath(endpoint, page, activeFilters, props);

  if (searchDirect) path += `&search=${encodeURIComponent(searchDirect)}`; //`&search=${encodeURIComponent(searchDirect)}`;

  if (activeOrders && Object.keys(activeOrders).length > 0) {
    let encodedOrder = orderByToPath(path, props, activeOrders);
    path += `&orderBy=${encodedOrder}`;
  }

  path = Replace({ store: props.store, params: location }, path);

  return path;
};

const getValuesFromKey = (
  key: string,
  activeFilters: { [key: string]: ActiveFilterValue },
  columns: any
): any[] => {
  const values: any[] = [];
  activeFilters[key].values.map((value: any) => {
    let val = getValueFromKey(key, value, columns);
    values.push({ _eq: `${val}` });
  });
  return values;
};

export const appendFilterToPath = (
  endpoint: string,
  page: number,
  activeFilters: { [key: string]: ActiveFilterValue },
  props: any
): string => {
  let path = `${endpoint}&page=${page}`;
  if (activeFilters && Object.keys(activeFilters).length > 0) {
    let filtersJSON: any = {};
    Object.keys(activeFilters).forEach((key, index) => {
      //Create object from key
      const keys: string[] = getIdFromKey(key, props.columns);
      //this to know if compound value
      const isCompound: boolean = keys.length > 1 ? true : false;
      //stores the whole json of this filter tag
      const content: any = isCompound
        ? compoundFilterProcess(key, keys, activeFilters, props)
        : simpleFilterProcess(key, keys, activeFilters, props);

      const whereType = activeFilters[key].useOr ? '_or' : '_and';
      if (!filtersJSON[whereType]) filtersJSON[whereType] = {};
      //precaution for overlapping keys
      let parentKey: string = Object.keys(filtersJSON[whereType])[0];
      if (!content || Object.keys(content).length === 0) return;
      if (parentKey === Object.keys(content)[0]) {
        //{key: {_or: []}} is the standard for all
        let general: [] = filtersJSON[whereType][parentKey]['_or'];
        let toPush: [] = content[parentKey]['_or'];
        if (general && toPush) {
          toPush.forEach((val) => {
            general.push(val);
          });
        }
      } else {
        Object.assign(filtersJSON[whereType], content);
      }
    });

    //encoding and sending json
    const serializedFilters = JSON.stringify(filtersJSON);
    const serializedAndEncoded = encodeURIComponent(serializedFilters);
    path += `&filters=${serializedAndEncoded}`;
  }
  return path;
};

const simpleFilterProcess = (
  key: string,
  keys: string[],
  activeFilters: { [key: string]: ActiveFilterValue },
  props: any
): any => {
  let content: any = {};
  keys.forEach((keyId) => {
    const parts: string[] = keyId.split('.');
    let current = content;
    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        if (!current['_or']) current['_or'] = [];
        let dataValues: any[] = getValuesFromKey(
          key,
          activeFilters,
          props.columns
        );
        if (dataValues.length >= 1) {
          dataValues.forEach((data) => {
            current['_or'].push({ [part]: data });
          });
        }
      } else {
        if (!current[part]) current[part] = {};
        current = current[part];
      }
    });
  });
  return content;
};

const compoundFilterProcess = (
  key: string,
  keys: string[],
  activeFilters: { [key: string]: ActiveFilterValue },
  props: any
): any => {
  const payload: any = [];
  activeFilters[key].values.map((value: any) => {
    let content: any = {};
    let andArray: any = [];
    keys.forEach((keyId: string) => {
      const parts: string[] = keyId.split('.');
      let keyNum: number = keys.length;
      let current = content;
      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          let val: [] = getValueFromPath(value, keyId);
          andArray.push({ [part]: { _eq: `${val}` } });
          if (keyNum == andArray.length) {
            if (!current['_or']) current['_or'] = [];
            current['_or'].push({ _and: andArray });
            andArray = [];
          }
        } else {
          if (!current[part]) current[part] = {};
          current = current[part];
        }
      });
    });
    //const andJSON: {} = { _and: content };
    //payload.push(andJSON);
    const magicNumber: number = 6;
    if (payload.length > 0) {
      let item: { [key: string]: any } = payload[0];
      for (let i = 0; i < magicNumber; i++) {
        let keys: string[] = Object.keys(item);
        if (keys[0] === '_or') break;
        item = item[keys[0]];
      }
      for (let i = 0; i < magicNumber; i++) {
        let keys: string[] = Object.keys(content);
        if (keys[0] === '_and') break;
        content = content[keys[0]];
      }
      if (item['_or']) item['_or'].push(content);
    } else payload.push(content);
  });
  //Object.assign(json['_or'], payload);
  let item: { [key: string]: any } = payload;
  let k: string[] = Object.keys(item);
  return item[k[0]];
};

/*
{nested: {last: 'desc' or 'asc'}}
*/
export const orderByToPath = (
  path: string,
  props: any,
  activeOrders: { [key: string]: string[] }
): string => {
  const payload: any[] = [];
  if (activeOrders && Object.keys(activeOrders).length > 0) {
    Object.keys(activeOrders).forEach((key) => {
      const keyId = getIdFromKey(key, props.columns);
      const keyValue = activeOrders[key];
      const content: any = {};
      keyId.forEach((keyId: string) => {
        const parts: string[] = keyId.split('.');
        let current = content;
        parts.forEach((part: any, index) => {
          if (index === parts.length - 1) {
            if (!current[part]) current[part] = {};
            current[part] = keyValue[0] == 'ASC' ? 'asc' : 'desc';
          } else {
            if (!current[part]) current[part] = {};
            current = current[part];
          }
        });
        payload.push(content);
      });
    });
  }
  let string = JSON.stringify({ payload });
  let encoded: string = encodeURIComponent(string);
  return encoded;
};

const constructCsvData = (items: any, columns: any) => {
  return items.map((item: any) => {
    let row: {[key: string]: string} = {};
    columns.forEach((column: any) => {
      if (column.type !== 'space' && column.label) {
        const value = getValueFromPath(item, column.label);
        const formated = formatColumnData(value, column);
        const sanitized = formated.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const sanitizedColumn = column.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        row[sanitizedColumn] = sanitized;
      }
    });
    return row;
  });
};

const formatColumnData = (value: any, column: any) => {
  if (!value && typeof value !== 'number') return '';
  switch (column.type) {
    case 'date':
      return column.format === 'nice' ? renderDateNice(value) : renderDateFromNow(value);
    case 'number':
      return renderNumber(column, value);
    case 'boolean':
      return value ? 'Yes' : 'No';  // Customize boolean representation as needed
    default:
      return value.toString(); // Convert other types to string
  }
};

export const downloadCSV = (items: any, columns: any,  filename = 'data.csv',) => {
  // Convert data array to CSV string using PapaParse
  //data = "Dog, Cat, Miau";
  //data = constructCsvData(items, columns);
  const data = constructCsvData(items, columns);
  const csv = Papa.unparse(data, {
    header: true,
    delimiter: ",",
  });
  //const formattedCSV = 'sep=,\n' + csv
  const formattedCSV = csv.replace(/,/g, ';')

  // Create a Blob from the CSV string
  const csvFile = new Blob([formattedCSV], { type: 'text/csv;charset=utf-8;' });

  // Create a link for downloading
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(csvFile);
  downloadLink.setAttribute('download', filename);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

