import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Replace } from '../../../utilities/replace/replace.utility';
import './index.scss';
interface DivisorComponentProps {
    title: string,
    description: string,
    icon?: string,
    close?: {
        path: string
    },
    styles?: any
}
function LayoutBarComponent(props: DivisorComponentProps) {

    const navigate = useNavigate();
    const paramsUlr = useParams()
    const { search } = useLocation();

    return (

        <div className='LayoutBarComponent' style={props.styles}>
            {props.icon && <div className={`icon ${props.icon}`} />}
            <div className='text'>
                <div className='title'>{props.title}</div>
                <div className='description'>{props.description}</div>
            </div>
            {
                props.close &&
                <i className='close la la-times' onClick={() => {

                    const queryparams: any = {}
                    if (search) {

                        if (search.split('?')) {

                            const queryparamsx = search.split('?')[1];

                            const params = queryparamsx.split('&');

                            let pair = null

                            params.forEach(function (d) {

                                pair = d.split('=');
                                const proper = pair[0]
                                queryparams[proper] = pair[1]

                            });

                        }

                    }

                    if (props.close) {

                        const path = Replace({ query: queryparams, params: paramsUlr }, props.close.path)
                        navigate(path);

                    }

                }} />
            }
        </div>

    );

}
export default LayoutBarComponent
