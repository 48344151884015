// Constants
import { useNavigate, useParams } from 'react-router';
import ExecuteActions from '../../../actions';
import { MessageConstants } from './message.constants';

// Interfaces
import { MessageProperties } from "./message.properties";

// Styles
import './message.styles.scss'

/**
 * Message Component
 * @description Component to display the message of the page
 * @param {MessageProperties} properties 
 * @returns {JSX.Element}
 */
export default function MessageComponent(properties: MessageProperties): JSX.Element {

   const locationParams = useParams();
   const navigation = useNavigate();
   const renderTitles = (): JSX.Element => {

      let icon = properties.icon;

      if (!properties.icon && properties.variant) {

         switch (properties.variant) {

            case 'success':
               icon = 'las la-check';
               break;

            case 'delete':

               icon = 'las la-times';
               break;

            default:
               icon = undefined;
               break;

         }

      }
      return <div>
         {icon && <i className={icon} />}

         <h1>{properties.title}</h1>

         <h2>{properties.subtitle}</h2>

      </div>

   }

   const classNames = [
      MessageConstants.CLASSNAME,
      ...properties.variant ? [properties.variant] : [],
   ].join(' ');

   return <div className={classNames}>

      {renderTitles()}
      <div className='buttons'>
         {properties.buttons && properties.buttons.map((button, index) => {

            return (
               <button key={index} onClick={() => {

                  ExecuteActions({
                     params: locationParams,
                     commands: {
                        navigation: navigation,

                     }, actions: button.actions
                  })

               }} type='button' className='TitleComponentButton'>
                  {button.text}
                  <i className='las la-arrow-right' />
               </button>
            );

         })}
      </div>
   </div>

}