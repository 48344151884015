/* eslint-disable */
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExecuteActions from '../../../actions';
import { setRoute } from '../../../store/actions';
import Stylize from '../../../stylize';
import LayoutScreenComponent from '../screen';
import './index.scss';

const mapDispatchToProps = {

    setRoute
}
const mapStateToProps = (state: any, ownProps: any) => (state)

interface LayoutSplashProps {
    setRoute: any,
    mask?: string,
    title: string,
    subtitle: string,
    description: string,
    video: string,
    styles?: any, components: any,

    buttons: { text: string, actions: any }[]
}
function LayoutSplashComponent(props: LayoutSplashProps) {
    const [videoReady, setVideoReady] = useState(false);
    const navigation = useNavigate()
    return (<Stylize className={`LayoutSplashComponent `} style={props?.styles}>
        {
            props.mask && <div className='mask' style={{ backgroundColor: `${props.mask}` }} />
        }
        <div className='slogan'>
            <div className='subtitle'>
                {props.subtitle}
            </div>
            <div className='title'>
                {props.title}
            </div>
            <div className='description'>
                {props.description}
            </div>
            <div className='buttons'>
                {props.buttons && props.buttons.map((button, index) => {
                    return (
                        <button key={index} onClick={() => {
                            ExecuteActions({
                                commands: {
                                    navigation: navigation
                                }, actions: button.actions
                            })
                        }} type='button' className='TitleComponentButton'>
                            {button.text}
                            <i className='las la-arrow-right' />
                        </button>
                    );
                })}
            </div>
        </div>
        <video className={`video ${videoReady && 'ready'}`} playsInline controls={false}
            autoPlay loop muted onLoadedData={
                () => {
                    setVideoReady(true)
                }

            }>

            <source src={`/assets/${props.video}`} type='video/mp4' />
        </video>

        {props?.components && <LayoutScreenComponent components={props?.components} />
        }

    </Stylize>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutSplashComponent)
