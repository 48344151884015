import { getProperty } from 'dot-prop';
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExecuteActions from "../../../actions";
import { setStore } from "../../../store/actions";
import LayoutScreenComponent from "../screen";

interface MiddlewareProps {
    children: any,
    store: any,
    setStore: (s: any) => void,
    components: any,
    failed?: {
        components?: any
        actions?: any
    },
    actions: any,
    conditions: {
        type: 'store',
        key: string,
        mode?: 'exist' | 'no-exist' | 'is',
        value: string
    }[]
}
const mapDispatchToProps = {
    setStore,
}
function MiddlewareComponent(props: MiddlewareProps) {

    const navigation = useNavigate()

    const validation = () => {

        const result = props.conditions.every((config: any) => {

            // Check if user is in store redux use
            if (config.type === 'store') {

                const currentValue = getProperty(props.store, config.key);
                if (config.mode === 'exist') {

                    if (currentValue) {

                        return true

                    }

                }
                if (config.mode === 'no-exist' || config.mode === 'nonexist') {

                    if (!currentValue) {

                        return true

                    }

                }
                if (config.mode === 'is' || !config.mode) {

                    if (currentValue === config.value) {

                        return true

                    }

                }

            }
            return false;

        });

        return result;

    }
    const [resultValidation, setResultValidation] = useState<boolean>(false);
    useEffect(() => {

        const resulting = validation();

        if (resulting) actionSuccess();
        else actionsFailed();
        setResultValidation(resulting)

    }, [props.store])
    const renderChildren = () => {

        if (props.components)
            return <LayoutScreenComponent direct components={props?.components} />
        return null;

    }
    const actionSuccess = () => {

        if (props.actions) {

            ExecuteActions({
                commands: {
                    store: props.setStore,
                    navigation: navigation
                }, actions: props.actions
            })

        }

    }
    const renderFailed = () => {

        if (props.failed?.components) {

            return <LayoutScreenComponent direct components={props.failed?.components} />

        }
        return null;

    }

    const actionsFailed = () => {

        if (props.failed?.actions) {

            ExecuteActions({
                commands: {
                    store: props.setStore,
                    navigation: navigation
                }, actions: props.failed?.actions
            })

        }

    }
    if (!props.conditions) return renderChildren();
    if (props.conditions.length === 0) return renderChildren();
    else if (resultValidation) {

        return renderChildren()

    } else {

        return renderFailed();

    }

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps, mapDispatchToProps)(MiddlewareComponent)
