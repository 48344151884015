import { ProductSelected } from "../../ticket.component"
import './products.ticket.styles.scss'
interface ProductsTicketProperties {
    products?: ProductSelected[],
    coin: string
}
export default function ProductsTicketComponent(settings: ProductsTicketProperties) {

    return <div className="ProductsTicketComponent">
        <div className="header">
            Productos <b>{settings.products?.length}</b>
        </div>
        <div className="products">
            {
                settings.products?.map((product, index) => {

                    return <div className="product" key={index}>
                        <div className="name">
                            {product.name}
                        </div>
                        <div className="price">
                            {product.price.toFixed(2)}{settings.coin}
                        </div>
                    </div>

                })
            }
        </div>
    </div>

}