
// Function login props redux connect
import { useEffect, useState } from 'react';
import './index.scss';

interface DispatchProps {
    name: string,
    type: 'checkbox'
    label: string,
    setData: (s: any) => void
    defaultValue?: boolean,
}

export default function LayoutFormCheckboxComponent(props: DispatchProps) {

    const [value, setValue] = useState<boolean>(false);
    useEffect(() => {

        if (props.defaultValue) {

            setValue(props.defaultValue);
            props.setData(props.defaultValue);

        }
        else {

            setValue(false);
            props.setData(false);

        }

    }, []);
    return (
        <div className='LayoutFormCheckboxComponent'>
            <div className={`box  ${value ? 'checked' : ''}`} onClick={() => {

                setValue(!value);
                props.setData(!value);

            }}>

                <div className='checkbox'>
                    <i className={value ? 'las la-check' : 'las la-times'} />

                </div>
                <label>{props.label}</label>
            </div>
        </div>

    );

}

