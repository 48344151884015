import { useEffect, useRef, useState } from 'react';
import './search.styles.scss'

interface ListProperties {
    onFocus?: (value: string) => void;
    onChange: (query: string) => void;
    placeholder: string;
}
export default function SearchComponent(props: ListProperties) {

    const [userValue, setUserValue] = useState('');

    return <div className="SearchComponent">
        <i className='lent las la-search' />
        <input type={'text'} onFocus={() => {

            props.onFocus?.(userValue)

        }}

            onChange={
                (event) => {

                    props.onChange(event.target.value);

                }
            } placeholder={props.placeholder} />

    </div>

}