export const environment = {
  brickter: {
    name: 'wifly',
  },
  production: false,
  debug: false,
  api: {
    host: 'https://api.wiflymobility.com',
  },
};
