// Function login props redux connect
import { useState } from 'react';
import './index.scss';

import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { useParams } from 'react-router-dom';
import { Replace } from '../../../../../utilities/replace/replace.utility';
import LoaderComponent from '../../../loader/loader.component';
import RequestAction from '../../../../../actions/request/request.action';

interface DispatchProps {
    type: 'download'
    label?: string,
    value: string,
    store: any,
}

function LayoutFormDownloadComponent(props: DispatchProps) {

    const [loading, setLoading] = useState(false);
    const params = useParams()

    const downloadFile = () => {

        if (props.value) {

            const currentUserToken = getProperty(props.store, 'user.access_token');
            let path = props.value;
            path = Replace({ store: props.store, params: params }, path);

            setLoading(true);
            // Check if have ?
            if (path.indexOf('?') > -1) {

                path = `${path}`

            }
            else {

                path = `${path}?`

            }

            // Replace id
            if (params['id']) {
                path = path.replace('{id}', params['id']);
            }

            RequestAction({
                endpoint: `${path}`,
                method: 'get',
                headers: {
                    'authorization': 'Bearer ' + currentUserToken
                }
            }).then((response: any) => {
                setLoading(false);

                // Download file
                const url =
                    window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                //
            });
        }
    }

    return (
        <div className='LayoutFormCheckboxComponent'>
            {
                loading ? <LoaderComponent status={loading} height={50} /> : <div className={`box `} onClick={() => {

                    // Download file:
                    downloadFile()

                }}>

                    <div className='checkbox'>

                    </div>
                    <label>{props.label}</label>
                </div>}
        </div>

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(LayoutFormDownloadComponent)
