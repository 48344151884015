import EndpointComponent from '../../development/endpoint/endpoint.component';
import VariableComponent from '../../development/variable/variable.component';
import LayoutButtonComponent from '../button'
import { environment } from '../../../../scheme/environments/environment';
import './index.scss';
import RequestAction from '../../../actions/request/request.action';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { useParams, useSearchParams } from 'react-router-dom';
import LoaderComponent from '../loader/loader.component';
import moment from 'moment-timezone';
import Conditional, { ConditionalType } from '../../../utilities/conditional/conditional.utility';
import { Replace } from '../../../utilities/replace/replace.utility';
import Stylize from '../../../stylize';
import React from 'react';
import 'moment/locale/es'
import { type } from '@testing-library/user-event/dist/type';
interface LayoutTableColumnType {
    id: string,
    title: string,
    icon?: string,
    decimals?: number,
    color?: string,
    format?: string,
    header?: {
        icon?: string
    }
    text?: string
    styles?: any,
    tooltip?: string,
    content?: LayoutTableColumnType[],
    conditions?: any,
    actions?: any,
    type: 'status' | 'mark' | 'space' | 'icon' | 'button' | 'text' | 'price' | 'image' | 'date' | 'number' | 'boolean',
    width?: number
}
interface LayoutTableProps {
    columns: LayoutTableColumnType[],
    results?: number
    data?: any[],
    store: any,
    query?: {
        [key: string]: {
            optional: boolean,
        }
    }
    header: boolean,
    endpoint: string,
    method: string,
    search?: boolean | {
        placeholder?: string
    }
}
function LayoutTableComponent(props: LayoutTableProps) {

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [cache, setCache] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [limit, setLimit] = useState(0);
    const location = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const queryCache = searchParams.get('cache');
        if (queryCache && queryCache !== cache) {

            setCache(queryCache);
            getData(0)

        }

    }, [searchParams])
    const getData = (page: number, searchDirect = search) => {

        /*search is the value in search bar */
       
        setLoading(true)
        setPage(page)
        if (!props.endpoint) return false;
        const currentUserToken = getProperty(props.store, 'user.access_token');
        // Replace to props.endpoint with {id}
        let endpoint = location['id'] ? props.endpoint.replace('{id}', location['id']) : props.endpoint
        // Replace all properties of location
        Object.keys(location).forEach((key) => {

            const value = location[key];
            if (value) endpoint = endpoint.replace(`{param.${key}}`, value)

        })
        // Endpoint add ?
        if (endpoint.indexOf('?') === -1) {

            endpoint = `${endpoint}?`

        }
        let path = `${endpoint}&page=${page}`;
        if (searchDirect) {

            path = `${path}&search=${searchDirect}`

        }
        path = Replace({ store: props.store, params: location }, path);
        if (props.query) {

            Object.keys(props.query).forEach((key) => {
                const value = searchParams.get(key);
                
                if (value) {
                    path = `${path}&${key}=${value}`
                }

            })
        }
        
        RequestAction({
            endpoint: path,
            method: props.method,
            headers: {
                'authorization': 'Bearer ' + currentUserToken
            }
        }).then((response: any) => {

            setTimeout(() => {

                setLoading(false)
                setItems(response.data.items)
                setCount(response.data.count)
                setLimit(response.data.limit)
            }, 300);

        }).catch((error) => {

            setLoading(false)

        })
        return true;

    }

    useEffect(() => {

        getData(0);

    }, [props.store])

    const validationConditionals = (item: any, column: LayoutTableColumnType) => {

        // Check condition
        let render = true;

        if (column.conditions) {

            render = Conditional(column.conditions, item);

        }
        return render

    }
    const renderDateFromNow = (value: any) => {

        moment.locale('es')

        const tz = moment.tz.guess();
        const date = moment(value).tz(tz);
        date.locale('es')
        return date.fromNow();

    }

    const renderDateNice = (value: any) => {

        const tz = moment.tz.guess();
        const date = moment(value).tz(tz);
        return date.format('DD/MM/YYYY HH:mm');

    }
    const renderColum = (index: string, item: any, column: LayoutTableColumnType) => {

        let condition = true;
        if (column.conditions) condition = validationConditionals(item, column)

        if (condition === false) return null;

        return <Stylize className='column' key={`${index}-sd`} style={{ ...column.styles, minWidth: column.width, maxWidth: column.width, width: column.width }}>
            {renderData(column, item)}
        </Stylize>

    }
    const renderNumber = (column: LayoutTableColumnType, value: any) => {

        if (column.decimals) {

            return value.toFixed(column.decimals);

        }
        return value;

    }
    const renderData = (column: LayoutTableColumnType, item: any): any => {

        let value: any = getProperty(item, column?.id)
        if (!value && typeof value !== 'number') {

            value = getProperty({ item }, `${column?.id}`)

        }

        return <>
            {
                !column.content && column.type === 'button' && <LayoutButtonComponent item={item} icon={column.icon} actions={column.actions} tooltip={column.tooltip} />

            }
            {
                column.content && column.type === 'button' && <LayoutButtonComponent item={item} icon={column.icon} actions={column.actions} tooltip={column.tooltip} children={renderContent(column, item)} />

            }
            {
                column.type === 'icon' && <div className={`table-icon ${column.icon}`} />

            }
            {
                column.type === 'image' && <div className='image'
                    style={{ backgroundImage: `url(/graphics/images/cosmetics/table/1.jpg)` }}>
                    <div className={`image ${column.icon}`} />
                </div>
            }
            {
                column.type === 'mark' && <div className='mark'>
                    <Stylize style={column.styles} className={`mark-box`}>
                        {
                            typeof column.color === 'string' && <div className='mark-color' style={{ backgroundColor: column.color }} />
                        }
                        {
                            typeof value === 'string' ? value : ''
                        }
                        {
                            typeof column.text === 'string' ? Replace({ item: item }, column.text) : ''
                        }
                    </Stylize></div>
            }
            {
                column.type === 'status' && <div className='status'><div className={`status-box 
                ${typeof value === 'boolean' && (value === true) && 'statusGreen'}
                ${typeof value === 'boolean' && (value === false) && 'statusRed'}
                ${typeof value === 'string' && (value === 'active') && 'statusGreen'}
                ${typeof value === 'string' && value === 'desactive' && 'statusRed'}
                ${typeof value === 'string' && value === 'finish' && 'statusGrey'}
                `}>
                    {
                        typeof value === 'string' ? value : ''
                    }

                </div></div>
            }
            {
                column.type === 'text' && <div className='text'>
                    {
                        typeof value === 'string' ? value : ''
                    }
                    {
                        typeof column.text === 'string' ? Replace({ item: item }, column.text) : ''
                    }
                </div>
            }
            {
                column.type === 'number' && <div className='text'>
                    {typeof value === 'number' && renderNumber(column, value)}
                </div>
            }
            {
                column.type === 'boolean' && <div className='icon'>
                    {typeof value === 'boolean' && value ? <i className=' las la-check' /> : <i className=' las la-times' />

                    }
                </div>
            }
            {
                column.format && column.type === 'date' && <div className='text' data-tip={column.tooltip && Replace({ item: { ...item, render: renderDateNice(value) } }, column.tooltip)} >
                    {
                        typeof value === 'string' ? renderDateNice(value) : ''
                    }
                </div>
            }

            {
                !column.format && column.type === 'date' && <div className='text' data-tip={column.tooltip && Replace({ item: { ...item, render: renderDateNice(value) } }, column.tooltip)} >
                    {
                        renderDateFromNow(value)
                    }
                </div>
            }

            {
                column.type === 'price' && <div className='price'>
                    <b>{
                        typeof (value) === 'number' ? value.toFixed(2) : value}</b>
                </div>
            }
            {
                column.content && column.type !== 'button' && <div className='content'>
                    {
                        renderContent(column, item)
                    }
                </div>
            }

        </>

    }
    const renderContent = (column: LayoutTableColumnType, item: any) => {

        return <>
            {
                column.content && typeof column.content === 'string' ? Replace({ item }, column.content) :
                    column.content && column.content.map((content: any, index: number) => {

                        const val = validationConditionals(item, content)
                        if (val) {

                            return <React.Fragment key={index}>
                                {renderData(content, item)}
                            </React.Fragment>

                        }
                        return null

                    })
            }
        </>

    }
    const empty = (!items || items.length === 0)
    return (

        <div className='LayoutTableComponent'>
            <LoaderComponent status={loading} />
            {
                !loading && <>

                    {
                        environment.debug && <EndpointComponent endpoint={props.endpoint} method={props.method} />
                    }
                    {
                        props.search !== false && <div className='search'>
                            <i className='icon las la-search' />
                            <input value={search} type={'text'}

                                autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                autoComplete='off'
                                name="search" onChange={
                                    (e) => {

                                        const value = e.target.value;

                                        setSearch(value);

                                    }
                                } placeholder={`Buscar...`} onKeyDown={
                                    (e) => {

                                        if (e.key === 'Enter') {

                                            getData(0)

                                        }

                                    }
                                } />
                            {
                                search && <i className='search-close las la-times' onClick={() => {

                                    setSearch('')
                                    getData(0, '')

                                }} />}
                            {
                                search && <i className='search-submit las la-arrow-right' onClick={() => {

                                    getData(0)

                                }} />}
                            {
                                environment.debug && <VariableComponent name={'search'} />
                            }
                        </div>

                    }

                    {
                        empty && <div className='empty'>
                            <b>SIN RESULTADOS</b>
                        </div>
                    }

                    {!empty && <div className='scroll'>
                        {
                            items && props.header ?
                                <div className='head'>
                                    {
                                        props.columns.map((column, index) => {

                                            if (column.type === 'space') return <div key={index} className='space' />

                                            return (
                                                <Stylize className='column' key={index} style={{ ...column.styles, minWidth: column.width, maxWidth: column.width, width: column.width }}>
                                                    {
                                                        column.header && column.header.icon ? <div className='icon'><i className={`  ${column.header.icon}`} /></div> : null
                                                    }
                                                    {
                                                        column.type === 'button' && !column.title && <div className='button'>

                                                            <div className={`icon ${column.icon}`} />
                                                        </div>
                                                    }

                                                    {
                                                        column.type === 'image' && <div className='image'>
                                                            <div className={`image ${column.icon}`} />
                                                        </div>
                                                    }
                                                    {
                                                        (column.type === undefined || (column.type === 'button' && column.title) || column.type === 'status' || column.type === 'text' || column.type === 'date' || column.type === 'number') && <div className='text'>
                                                            {column.title}
                                                        </div>
                                                    }
                                                    {
                                                        column.type === 'price' && <div className='price'>
                                                            {column.title}
                                                        </div>
                                                    }
                                                    {
                                                        environment.debug && column?.id && <VariableComponent name={column?.id} />
                                                    }
                                                </Stylize>
                                            );

                                        })
                                    }
                                </div> : null}
                        <div className='body'>
                            {
                                items.map((item: any, rowIndex) => {

                                    return <div className='row' key={rowIndex}>
                                        {
                                            props.columns.map((column, indexr) => {

                                                if (column.type === 'space') return <div key={`${rowIndex}-${indexr}`} className='space' />
                                                return (renderColum(`${rowIndex}-${indexr}`, item, column));

                                            })
                                        }
                                    </div>

                                })
                            }
                        </div>

                    </div>}
                    {
                        limit &&

                        <div className='pagination'>
                            <div className='count'><b>{count} resultados </b> </div>
                            <div className='controls'>
                                {
                                    page > 0 && <div className='button back' onClick={() => {

                                        getData(page - 1)

                                    }}>
                                        <i className='icon las la-angle-left' />
                                    </div>
                                }
                                {
                                    (count / limit - 1) > page && <div className='button next' onClick={() => {

                                        getData(page + 1)

                                    }}>
                                        <i className='icon las la-angle-right' />
                                    </div>
                                }

                            </div>
                        </div>}

                </>}
            {
                environment.debug && <EndpointComponent endpoint={`${count} elements with limit ${limit}`} method={`${page} current page`} />
            }
        </div>

    );

}
const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(LayoutTableComponent)