
import { environment } from './environments/environment';
import WiflyConstructor from './wifly.constructor.js';
export default function SchemeConstructor() {

    if (environment.brickter.name === 'wifly') {

        return WiflyConstructor()

    }
    return null

}