import './endpoint.styles.scss'
export default function EndpointComponent(props: { action?: string, endpoint: string, method: string }) {

    return <div className="EndpointComponent">
        <i className='las la-server'></i>
        {
            props.action && <div className='action'>{props.action}</div>
        }
        <div className='endpoint'>{props.endpoint}</div>
        <div className='method'>{props.method}</div>
    </div>

}