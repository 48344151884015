import { getProperty } from 'dot-prop';

export function Replace(data: any, text: string | string[]): any {

  const result = ReplaceObject(data, text);
  if (typeof result === 'string') {

    return ReplaceClean(result);

  }
  if (typeof result === 'number') {

    return result;

  }
  return '';

}

function ReplaceObject(data: any, text: string | string[]): any {

  if (typeof text === 'number') {

    return text;

  }

  if (typeof text === 'string') {

    const keys = text.match(/{[a-zA-Z0-9_.]+}/g);
    if (keys) {

      keys.forEach((keyToReplace) => {

        const key_ = keyToReplace.replace(/[{}]/g, '');
        if (key_ && data) {

          const value = getProperty(data, key_);

          if (value || value === 0) {

            if (typeof text === 'string') {

              text = text.replace(keyToReplace, value);

            }

          }

        }

      });

    }
    return text;

  }
  if (Array.isArray(text)) {

    return text.map((item: string) => {

      return Replace(data, item);

    });

  }
  return null;

}

function ReplaceClean(text: string) {

  return text.replace(/{[a-zA-Z0-9_.]+}/g, '');

}
