import Stylize from '../../../stylize';
import './index.scss';
interface ButtonComponentProps {
    image: string,
    title: string,
    styles: any,
    onPress?: () => void
}
function LayoutLogotypeComponent(props: ButtonComponentProps) {

    return (

        <Stylize className='LayoutLogotypeComponent' style={props.styles}>
            <img className='image' onClick={props?.onPress} alt={props.title} src={`/assets/${props.image}`} />
        </Stylize>

    );

}
export default LayoutLogotypeComponent
