// Function login props redux connect
import { Fragment, useEffect, useState } from 'react';
import WiflySearchComponent from './search/search.wifly.component';
import './select.wifly.style.scss';
import RequestAction from '../../../actions/request/request.action';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { useParams } from 'react-router-dom';
import { Replace } from '../../../utilities/replace/replace.utility';
import LoaderComponent from '../../layout/loader/loader.component';
import ReactMarkdown from '../../layout/markdown';
import { resourceUsage } from 'process';
import {
  buttonFilter,
  distinctToPath,
  filtersToPath,
  getValueFromPath,
} from './utils.select.wifly';

interface ActiveFilters {
  [key: string]: string[];
}

interface DispatchProps {
  item?: any;
  tableItems?: any;
  name: string;
  category: string;
  path?: string;
  type?: string;
  bubble?: string;
  bubbles?: {
    content: string;
    background?: string;
  }[];
  onApplyFilters: (key: string, values: string[]) => void;
  onApplyCloseWindow: () => void;
  activeFilters: any[];
  icon?: string;
  id: string;
  selectFirst?: boolean;
  count?: number;
  distinctSearchId?: string;
  options?: { id: string; label: string }[];
  multiple?: boolean;
  defaultValue?: string | string[];
  placeholder: string;
  requireSearch?: boolean;
  setData: (s: any) => void;
  store: any;
  content: any;
  idSearch?: string;
  label: string;
  disabled?: boolean;
  openButton?: boolean;
  maxItemsPage?: number;
}

const isNumeric = (value: any) => {
  return /^-?\d+$/.test(value);
};

const renderOptionTitle = (item: any, label: any, content: any) => {
  if (!Array.isArray(label)) {
    let value = Replace({ item: item }, label);
    if (content && content.length > 0) {
      if (content[0].type && content[0].type === 'boolean') {
        value = value ? '✓' : 'x';
      } else if (content[0].conditions && content[0].conditions[0]) {
        value = value === '' ? content[0].text : value;
      }
    }
    return value;
  }

  //if not do complex
  let result: string = '';
  label.forEach((val: string) => {
    result += Replace({ item: item }, val) + ' ';
  });
  return result;
};

const renderBubble = (item: any, bubbleSelector: string) => {
  const text = Replace({ item: item }, bubbleSelector);
  return (
    <div className="bubble">
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

function WiflySelectComponent(props: DispatchProps) {
  const id = props.id || '{item.id}';
  const idSearch = props.idSearch || 'id';
  const label = props.label || '{item.label}';
  const type = props.type || undefined;
  const tableItems = props.tableItems || undefined;
  const distinctSearchId = props.distinctSearchId || undefined;
  const content: any[] = Array.isArray(props.content) ? props.content : [];
  const activeFilters = props.activeFilters;
  const [items, setItems] = useState<any[]>([]);
  const [count, setCount] = useState<number>(props.count || 0);
  const [limit, setLimit] = useState<number>(0);
  const [search, setSearch] = useState<string | undefined>();
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const openButton = props.openButton || false;
  const maxItemsPage = props.maxItemsPage || 7;
  const [open, setOpen] = useState<boolean>(!openButton);

  const params = useParams();

  const toggleSelection = (selectedItem: any) => {
    setSelected((prevSelected: any) => {
      const isSelected = prevSelected.some(
        (item: any) => item.id === selectedItem.id
      );
      if (isSelected) {
        return prevSelected.filter((item: any) => item.id !== selectedItem.id);
      } else {
        return [...prevSelected, selectedItem];
      }
    });
  };

  const removeItem = (idToRemove: any) => {
    setSelected(selected.filter((item: any) => item.id !== idToRemove));
  };
  const clearSelection = () => {
    setSelected([]);
  };
  const handleApply = () => {
    props.onApplyFilters(props.category, selected);
  };
  const requestWindowClose = () => {
    props.onApplyCloseWindow();
  };

  const getOptions = (
    search?: string,
    page: number = 0,
    localLimit: number = 20
  ) => {
    if (props.requireSearch && !search) return;
    if (props.path) {
      const currentUserToken = getProperty(props.store, 'user.access_token');
      let path = props.path;
      path = Replace(
        { store: props.store, params: params, item: props.item },
        path
      );
      setLoading(true);
      // Check if have ?
      if (path.indexOf('?') > -1) path = `${path}`;
      else path = `${path}?`;
      // search to filter to avoid oversearching columns
      if (search)
        path = filtersToPath(path,search, props);
      //Add distinct
      if (distinctSearchId != undefined)
        path = distinctToPath(path, distinctSearchId, content);

      if (type === 'buttonFilter')
        path = buttonFilter(path, tableItems, distinctSearchId, content);
      // If finish in ?
      // Replace id
      if (params['id']) path = path.replace('{id}', params['id']);
      RequestAction({
        endpoint: `${path}&limit=${maxItemsPage}&page=${page}`,
        method: 'get',
        headers: {
          authorization: 'Bearer ' + currentUserToken,
        },
      })
        .then((response: any) => {
          setLoading(false);
          setItems(response.data.items);
          setCount(response.data.count);
          setLimit(response.data.limit);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (!props.requireSearch) {
      if (props.options) {
        // Get option by id with defaultValue
        setItems(props.options);
        if (props.defaultValue) {
          let defaultValue = Replace(
            { store: props.store },
            props.defaultValue
          );
          if (isNumeric(defaultValue)) {
            defaultValue = parseInt(defaultValue);
          }
          const options = props.options;
          const selected = options.filter((option: any) => {
            let value = Replace({ item: option, store: props.store }, id);
            if (isNumeric(defaultValue)) {
              value = parseInt(value);
            }
            return value === defaultValue;
          });
          setSelected(selected);
        }
      }
      const defaultValue =
        props.defaultValue &&
        Replace({ store: props.store }, props.defaultValue);

      if (props.path && props.defaultValue && defaultValue) {
        const currentUserToken = getProperty(props.store, 'user.access_token');
        const path = props.path;
        // Check if have ?

        const defaultValue = Replace(
          { store: props.store },
          props.defaultValue
        );
        if (defaultValue) {
          // Check if have ?
          let pathUse = '';
          if (path.indexOf('?') > -1) {
            // First part
            const pathFirst = path.split('?')[0];
            // Second part
            const pathSecond = path.split('?')[1];
            // Replace id
            pathUse = `${pathFirst}/${defaultValue}?${pathSecond}`;
          } else {
            pathUse = `${path}/${defaultValue}`;
          }
          const pathUseReplace = Replace(
            { store: props.store, item: props.item },
            pathUse
          );
          setLoading(true);
          RequestAction({
            endpoint: `${pathUseReplace}`,
            method: 'get',
            headers: {
              authorization: 'Bearer ' + currentUserToken,
            },
          })
            .then((response: any) => {
              setLoading(false);
              setSelected(response.data.items);
              props.setData(defaultValue);
            })
            .catch((error) => {
              //
            });
        }
      } else if (props.selectFirst && props.path) {
        const currentUserToken = getProperty(props.store, 'user.access_token');

        const pathUseReplace = Replace({ store: props.store }, props.path);
        setLoading(true);
        RequestAction({
          endpoint: `${pathUseReplace}`,
          method: 'get',
          headers: {
            authorization: 'Bearer ' + currentUserToken,
          },
        })
          .then((response: any) => {
            setLoading(false);
            if (response.data.items.length > 0) {
              setSelected([response.data.items[0]]);
              const value = Replace({ item: response.data.items[0] }, id);

              props.setData(value);
            }
          })
          .catch((error) => {
            //
          });
      }
    }
    if (!openButton) getOptions();
    if (activeFilters) setSelected(activeFilters);
  }, []);

  //HTML body Start
  return (
    <div className="WiflySelectComponent">
      {/*ACTIVE FILTERS*/}
      <div className="selected-filters flex flex-wrap gap-2">
        {selected.map((item: any, index: number) => (
          <div
            key={index}
            className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded flex items-center justify-between"
          >
            {getValueFromPath(item, props.label, content)}
            <button
              onClick={() => removeItem(item.id)}
              className="ml-2 rounded-full p-0.5 inline-flex items-center justify-center text-blue-500 hover:text-red-500 focus:outline-none"
            >
              &times;
            </button>
          </div>
        ))}
      </div>

      {/*FILTER SEARCH LOGIC*/}
      {openButton && (
        <div className="input">
          <div className={`items ${open && 'opening'}`}>
            {selected ? (
              <div
                className={`item selected ${props.disabled ? 'disabling' : ''}`}
                onClick={() => {
                  if (props.disabled) {
                  } else {
                    if (!open) getOptions();
                    setOpen(!open);
                  }
                }}
              >
                {
                  <div className="selectedRoot">
                    {props.icon && <i className={`icon las ${props.icon}`} />}
                    {selected && selected.length > 0 ? (
                      selected.map((item: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            <span>
                              <i className="las la-check" />
                            </span>
                            <div key={index} className="selected">
                              {renderOptionTitle(item, label, content)}
                              {props.multiple && (
                                <i
                                  className="las la-times"
                                  onClick={() => {
                                    setSelected(
                                      selected.filter((s: any) => {
                                        const value = Replace({ item: s }, id);
                                        const valueItem = Replace(
                                          { item: item },
                                          id
                                        );

                                        return value !== valueItem;
                                      })
                                    );
                                  }}
                                />
                              )}
                            </div>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="placeholder">
                        {props.placeholder || 'Selecciona...'}
                      </div>
                    )}
                    {}
                  </div>
                }
                {open && <i className="angle las la-angle-up" />}
                {!open && (
                  <>
                    {props.disabled ? (
                      <i className="disabled las la-lock" />
                    ) : (
                      <i className="angle las la-angle-down" />
                    )}
                  </>
                )}
              </div>
            ) : (
              <div
                className="item"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <span></span>
                <b>{props.placeholder || 'Select a option'}</b>
                {open && <i className="angle las la-angle-up" />}
                {!open && <i className="angle las la-angle-down" />}
              </div>
            )}
          </div>
        </div>
      )}

      {/*SEARCH COMPONENT*/}
      {open && (
        <div className="window">
          <div className="items">
            {props.path && open && (
              <WiflySearchComponent
                onChange={(query: string) => {
                  setPage(0);
                  setItems([]);
                  setSearch(query);
                  getOptions(query);
                }}
                placeholder={`${props.category}`}
              />
            )}
            {loading && <LoaderComponent status={loading} height={50} />}
            {loading === false && items.length === 0 && (
              <div className="empty">
                <b>Sin resultados</b>
              </div>
            )}
            {/*CONTENT LISTING*/}
            {loading === false &&
              open &&
              items.map((item, index) => {
                return (
                  <div
                    className="item selectable"
                    key={index}
                    onClick={() => {
                      setPage(0);
                      if (props.multiple) {
                        setSelected([...selected, item]);
                        // Get all ids of selected:
                        const ids = selected.map((s: any) => {
                          const value = Replace({ item: s }, id);

                          return value;
                        });
                        const value = getProperty({ item: item }, id);

                        props.setData([...ids, value]);
                      } else {
                        toggleSelection(item);
                        const value = Replace({ item: item }, id);
                        // Check if number in text
                        if (isNumeric(value)) {
                          props.setData(parseInt(value));
                        } else {
                          props.setData(value);
                        }
                      }
                      setOpen(false);
                    }}
                  >
                    {
                      // Is in selected
                      selected &&
                      selected.find((s: any) => {
                        const value = Replace({ item: s }, id);

                        const valueItem = Replace({ item: item }, id);
                        return value === valueItem;
                      }) ? (
                        <span>
                          <i className="las la-check" />
                        </span>
                      ) : (
                        <span></span>
                      )
                    }

                    <b className="text">
                      {renderOptionTitle(item, label, content)}
                    </b>
                    {props.bubble && renderBubble(item, props.bubble)}
                    {props.bubbles &&
                      props.bubbles.map((bubble: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            {renderBubble(item, bubble.content)}
                          </Fragment>
                        );
                      })}
                  </div>
                );
              })}{' '}
          </div>
        </div>
      )}

      {/*PAGINATION*/}
      {open === true && limit > 0 && (
        <div className="pagination">
          <div className="count">
            <b>{page + 1}</b> / {Math.ceil(count / limit)}
          </div>
          <div className="controls">
            {page > 0 && (
              <div
                className="button back"
                onClick={() => {
                  setPage(page - 1);
                  getOptions(search, page - 1);
                }}
              >
                <i className="icon las la-angle-left" />
              </div>
            )}

            {(page + 1) * limit < count && (
              <div
                className="button next"
                onClick={() => {
                  setPage(page + 1);
                  getOptions(search, page + 1);
                }}
              >
                <i className="icon las la-angle-right" />
              </div>
            )}
          </div>
        </div>
      )}

      {/*RESULT NUMBER*/}
      <div>
        <div className="font-bold">{count === -1 ? 0 : count} resultados</div>
      </div>

      {/*APPLY/CANCEL BUTTONS*/}
      <div className="flex justify-between mt-4 px-4">
        <button
          onClick={() => {
            handleApply();
            requestWindowClose();
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Apply
        </button>
        <button
          onClick={clearSelection}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Clear
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any, ownProps: any) => state;

export default connect(mapStateToProps)(WiflySelectComponent);
