
// Function login props redux connect
import { useEffect, useState } from 'react';

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './index.scss';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'draft-js/dist/Draft.css';
interface DispatchProps {
    name: string,
    placeholder: string,
    setData: (s: any) => void
    defaultValue?: string,
    disabled?: boolean,
}

export default function LayoutFormEditorComponent(props: DispatchProps) {

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    useEffect(
        () => {

            if (props.defaultValue) {

                const content = htmlToDraft(props.defaultValue);

                if (content) {

                    const contentState = ContentState.createFromBlockArray(
                        content.contentBlocks
                    );
                    setEditorState(EditorState.createWithContent(contentState));

                }

            }

        }, [])

    return (
        <div className='LayoutFormEditorComponent'>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
                onContentStateChange={(contentState: any) => {

                    const rawContentState = convertToRaw(editorState.getCurrentContent());

                    const markup = draftToHtml(
                        rawContentState
                    );
                    props.setData(markup)

                }}
                textAlignment="left"
                toolbar={{
                    options: ['inline', 'blockType', 'list',],
                    inline: {
                        options: ["bold", "italic", "underline", "strikethrough",],
                    },

                }}

            />
        </div>

    );

}
