// eslint-disable no-console, no-control-regex
import Axios from 'axios';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { useEffect, useRef, useState } from 'react';
import Map, { Marker, MarkerDragEvent, NavigationControl } from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { connect } from 'react-redux';
import GeocoderControl from './geocoder';
import './index.scss';
import Pin from './pin';

// @ts-ignore
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface DispatchProps {
    name: string,
    type: 'text' | 'password' | 'hidden'
    store: any
    placeholder: string,
    icon?: string,
    setData: (s: any) => void
    defaultValue?: any,
}
function FormMapComponent(props: DispatchProps) {

    const [defaultValue, setDefaultValue] = useState<any>({
        longitude: -22.4,
        latitude: 37.8,
    });
    const [marker, setMarker] = useState<any>({
        longitude: -122.4,
        latitude: 37.8,
    });

    const mapRef = useRef<any>(null);

    useEffect(() => {

        if (props.defaultValue) {

            if (props.defaultValue && typeof props.defaultValue === 'string') {

                const explo = props.defaultValue.split(',');
                setDefaultValue({
                    latitude: explo[1],
                    longitude: explo[0],
                })
                props.setData([parseFloat(explo[0]), parseFloat(explo[1])]);
                setMarker({
                    latitude: parseFloat(explo[1]),
                    longitude: parseFloat(explo[0]),
                })
                if (mapRef.current) {

                    mapRef.current.getMap().setCenter({ lat: explo[1], lng: explo[0] });

                }

            }

        }
        else {

            Axios.get('https://ipapi.co/json/').then((response) => {

                const data = response.data;
                setDefaultValue({
                    latitude: data.latitude,
                    longitude: data.longitude,
                })
                setMarker({
                    latitude: data.latitude,
                    longitude: data.longitude,
                })
                props.setData([data.longitude, data.latitude]);

            }).catch((error) => {

            });

        }

    }, [])

    const onMarkerDragEnd = (event: MarkerDragEvent) => {

        setMarker({
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat,
        });
        props.setData([event.lngLat.lng, event.lngLat.lat]);

    }
    const TOKEN = 'pk.eyJ1Ijoic2FsYmF0b3JlIiwiYSI6ImNsNXpueGZ4bjBndDkzam5hc3cwZ2NpbnUifQ.2H07Hkz46tfvVK-rd83LsA'
    return (
        <div className='LayoutFormMapComponent' style={{ display: props.type === 'hidden' ? 'none' : undefined }}>

            <Map
                ref={mapRef}
                onLoad={() => {

                    if (mapRef.current) {

                        mapRef.current.getMap().setCenter({ lat: defaultValue.latitude, lng: defaultValue.longitude });

                    }

                }}
                initialViewState={{
                    longitude: defaultValue.longitude,
                    latitude: defaultValue.latitude,
                    zoom: 2
                }}
                scrollZoom={false}
                mapboxAccessToken={TOKEN}

                style={{ height: 300, borderRadius: 5, overflow: 'hidden' }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
            > <NavigationControl />
                <GeocoderControl

                    placeholder=' '
                    mapboxAccessToken={TOKEN} position="top-left" onResult={(ssss: any) => {

                        if (ssss?.result?.center) {

                            setMarker({
                                longitude: ssss.result.center[0],
                                latitude: ssss.result.center[1],
                            });
                            props.setData([ssss.result.center[0], ssss.result.center[1]]);

                        }

                    }} />
                {marker && <Marker
                    key={marker.longitude}
                    longitude={marker.longitude}
                    latitude={marker.latitude}
                    anchor="bottom"
                    style={{ height: 30, width: 30, padding: 0, margin: 0, fontSize: 0 }}
                    draggable
                    onDragEnd={onMarkerDragEnd}
                ><Pin size={30} /></Marker>
                }
            </Map>

        </div>

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(FormMapComponent)
