/* import axios from 'axios';
import { environment } from '../../../scheme/environments/environment';

export default function RequestAction(props: any, parseDataArray?: boolean) {

    console.log(`Request: ${environment.api.host}${props.endpoint}`);

    // If parseDataArray is true, send individual requests for each item in props.data
    if (parseDataArray && Array.isArray(props.data)) {

        const requests = props.data.map((item: any) => {

            return axios({
                method: props.method,
                url: `${environment.api.host}${props.endpoint}`,
                data: item,
                headers: props.headers
            });
        
        });

        // Return a Promise that resolves when all requests are completed
        return Promise.all(requests);

    }

    // Otherwise, send a single request with the entire props.data
    return axios({

        method: props.method,
        url: `${environment.api.host}${props.endpoint}`,
        data: props.data,
        headers: props.headers
    
    });

} */

import axios from 'axios';
import { environment } from '../../../scheme/environments/environment'

export default function RequestAction(props: any) {

    console.log(`Request: ${environment.api.host}${props.endpoint}`)
    return axios({
        method: props.method,
        url: `${environment.api.host}${props.endpoint}`,
        data: props.data,
        headers: props.headers
    });

}