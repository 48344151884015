
// Function login props redux connect
import './index.scss';
import { environment } from '../../../../../../scheme/environments/environment'
import VariableComponent from '../../../../development/variable/variable.component';
import LayoutButtonComponent, { ButtonComponentProps } from '../../../button'

interface DispatchProps {
    title: string,
    description?: string,
    icon?: string,
    name?: string,
    buttons?: ButtonComponentProps[]
}

export default function InputComponent(props: DispatchProps) {

    return (
        <div className='LayoutFormTitleComponent'>
            <div className='top'>
                {props.title && <div className='title'>{props.title}</div>}

                <div className='buttons'>
                    {props.buttons && props.buttons.map((button, index) => {

                        return (

                            <LayoutButtonComponent text={button.text} icon={button.icon} actions={button.actions} tooltip={button.tooltip} />
                        );

                    })}
                </div>
                {
                    environment.debug && props.name && <VariableComponent name={props.name} />
                }
            </div>
            {props.description && <div className='description'>{props.description}</div>}
        </div>

    );

}

