
// Function login props redux connect
import { Fragment, useEffect, useState } from 'react';
import SearchComponent from './search/search.component';
import './index.scss';
import RequestAction from '../../../../../actions/request/request.action';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { useParams } from 'react-router-dom';
import { Replace } from '../../../../../utilities/replace/replace.utility';
import LoaderComponent from '../../../loader/loader.component';
import ReactMarkdown from '../../../markdown';

interface DispatchProps {
    item?: any;
    name: string,
    path?: string,
    bubble?: string,
    bubbles?: {
        content: string,
        background?: string
    }[],
    icon?: string,
    id?: string,
    selectFirst?: boolean,
    options?: { id: string, label: string }[],
    multiple?: boolean,
    defaultValue?: string | string[],
    placeholder: string,
    requireSearch?: boolean,
    setData: (s: any) => void
    store: any,
    idSearch?: string,
    label?: string
    disabled?: boolean

}

function LayoutFormSelectComponent(props: DispatchProps) {

    const id = props.id || '{item.id}';
    const idSearch = props.idSearch || 'id';
    const label = props.label || '{item.label}';
    const [items, setItems] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(0);
    const [search, setSearch] = useState<string | undefined>();
    const [page, setPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const params = useParams()
    const getOptions = (search?: string, page: number = 0) => {

        if (props.requireSearch && !search) return;
        if (props.path) {

            const currentUserToken = getProperty(props.store, 'user.access_token');
            let path = props.path;
            path = Replace({ store: props.store, params: params, item: props.item }, path);
            setLoading(true);
            // Check if have ?
            if (path.indexOf('?') > -1) {

                path = `${path}`

            }
            else {

                path = `${path}?`

            }
            // Add search
            if (search) {

                path = `${path}&search=${search}`

            }
            // If finish in ?

            // Replace id
            if (params['id']) path = path.replace('{id}', params['id'])

            RequestAction({
                endpoint: `${path}&page=${page}`,
                method: 'get',
                headers: {
                    'authorization': 'Bearer ' + currentUserToken
                }
            }).then((response: any) => {

                setLoading(false);
                setItems(response.data.items);
                setCount(response.data.count);
                setLimit(response.data.limit);

            }
            ).catch((error) => {
                //
            });

        }

    }

    useEffect(() => {

        if (!props.requireSearch) {

            if (props.options) {

                // Get option by id with defaultValue
                setItems(props.options)
                if (props.defaultValue) {

                    let defaultValue = Replace({ store: props.store }, props.defaultValue);
                    if (isNumeric(defaultValue)) {

                        defaultValue = (parseInt(defaultValue));

                    }
                    const options = props.options;
                    const selected = options.filter((option: any) => {

                        let value = Replace({ item: option, store: props.store }, id);
                        if (isNumeric(defaultValue)) {

                            value = (parseInt(value));

                        }
                        return value === defaultValue

                    }
                    )
                    setSelected(selected)

                }

            }
            const defaultValue = props.defaultValue && Replace({ store: props.store }, props.defaultValue);

            if (props.path && props.defaultValue && defaultValue) {

                const currentUserToken = getProperty(props.store, 'user.access_token');
                const path = props.path;
                // Check if have ?

                const defaultValue = Replace({ store: props.store }, props.defaultValue);
                if (defaultValue) {

                    // Check if have ?
                    let pathUse = '';
                    if (path.indexOf('?') > -1) {

                        // First part
                        const pathFirst = path.split('?')[0];
                        // Second part
                        const pathSecond = path.split('?')[1];
                        // Replace id
                        pathUse = `${pathFirst}/${defaultValue}?${pathSecond}`

                    }
                    else {

                        pathUse = `${path}/${defaultValue}`

                    }
                    const pathUseReplace = Replace({ store: props.store, item: props.item }, pathUse);
                    setLoading(true)
                    RequestAction({
                        endpoint: `${pathUseReplace}`,
                        method: 'get',
                        headers: {
                            'authorization': 'Bearer ' + currentUserToken
                        }
                    }).then((response: any) => {

                        setLoading(false)
                        setSelected(response.data.items);
                        props.setData(defaultValue);

                    }
                    ).catch((error) => {
                        //
                    });

                }

            }
            else if (props.selectFirst && props.path) {

                const currentUserToken = getProperty(props.store, 'user.access_token');

                const pathUseReplace = Replace({ store: props.store }, props.path);
                setLoading(true)
                RequestAction({
                    endpoint: `${pathUseReplace}`,
                    method: 'get',
                    headers: {
                        'authorization': 'Bearer ' + currentUserToken
                    }
                }).then((response: any) => {

                    setLoading(false)
                    if (response.data.items.length > 0) {

                        setSelected([response.data.items[0]]);
                        const value = Replace({ item: response.data.items[0] }, id);

                        props.setData(value);

                    }

                }
                ).catch((error) => {
                    //
                });

            }

        }

    }, [])

    const renderOptionTitle = (item: any) => {

        return Replace({ item: item }, label)

    }

    const renderBubble = (item: any, bubbleSelector: string) => {

        const text = Replace({ item: item }, bubbleSelector)
        return <div className="bubble"><ReactMarkdown>{text}</ReactMarkdown></div>

    }

    const isNumeric = (value: any) => {

        return /^-?\d+$/.test(value);

    }
    return (
        <div className='LayoutFormSelectComponent'>

            <div className='input'>
                <div className={`items ${open && 'opening'}`}>
                    {
                        selected ? <div className={`item selected ${props.disabled ? 'disabling' : ''}`} onClick={() => {

                            if (props.disabled) {

                            }
                            else {

                                if (!open) getOptions()
                                setOpen(!open);

                            }

                        }}>

                            {
                                <div className='selecteds'>
                                    {props.icon && <i className={`icon las ${props.icon}`} />}
                                    {
                                        (selected && selected.length > 0) ? selected.map((item: any, index: number) => {

                                            return <Fragment key={index}>
                                                <span><i className='las la-check' /></span>
                                                <div key={index} className='selected'>{renderOptionTitle(item)}
                                                    {
                                                        props.multiple && <i className='las la-times' onClick={() => {

                                                            setSelected(selected.filter((s: any) => {

                                                                const value = Replace({ item: s }, id);
                                                                const valueItem = Replace({ item: item }, id);

                                                                return value !== valueItem

                                                            }));

                                                        }
                                                        } />
                                                    }
                                                </div></Fragment>

                                        }) : <div className='placeholder'>{props.placeholder || 'Selecciona...'}</div>
                                    }
                                    {

                                    }
                                </div>}
                            {
                                open && <i className='angle las la-angle-up' />
                            }
                            {
                                !open && <>
                                    {
                                        props.disabled ? <i className='disabled las la-lock' /> : <i className='angle las la-angle-down' />
                                    }
                                </>
                            }
                        </div> : <div className='item' onClick={() => {

                            setOpen(!open);

                        }}>
                            <span></span>
                            <b>{props.placeholder || 'Select a option'}</b>
                            {
                                open && <i className='angle las la-angle-up' />
                            }
                            {
                                !open && <i className='angle las la-angle-down' />
                            }
                        </div>
                    }

                </div></div>

            {
                open && <div className='window'>

                    <div className='items'>
                        {
                            props.path && open && <SearchComponent onChange={(query: string) => {

                                setPage(0)
                                setItems([]);
                                setSearch(query)
                                getOptions(query)

                            }} placeholder={''} />
                        }
                        {
                            loading && <LoaderComponent status={loading} height={50} />
                        }
                        {
                            loading === false && items.length === 0 && <div className='empty'>
                                <b>Sin resultados</b>
                            </div>
                        }
                        {
                            // List items
                            loading === false && open && items.map((item, index) => {

                                return <div className='item selectable' key={index} onClick={() => {

                                    setPage(0)
                                    if (props.multiple) {

                                        setSelected([...selected, item]);
                                        // Get all ids of selected:
                                        const ids = selected.map((s: any) => {

                                            const value = Replace({ item: s }, id);

                                            return value

                                        });
                                        const value = getProperty({ item: item }, id);

                                        props.setData([...ids, value]);

                                    } else {

                                        setSelected([item]);
                                        const value = Replace({ item: item }, id);
                                        // Chec if number in text
                                        if (isNumeric(value)) {

                                            props.setData(parseInt(value));

                                        }

                                        else props.setData(value);

                                    }
                                    setOpen(false);

                                }}>
                                    {
                                        // Is in selected
                                        selected && selected.find((s: any) => {

                                            const value = Replace({ item: s }, id);

                                            const valueItem = Replace({ item: item }, id);

                                            return value === valueItem

                                        }) ? <span><i className='las la-check' /></span> : <span></span>

                                    }

                                    <b className='text'>{renderOptionTitle(item)}</b>
                                    {
                                        props.bubble && renderBubble(item, props.bubble)
                                    }
                                    {
                                        props.bubbles && props.bubbles.map((bubble: any, index: number) => {

                                            return <Fragment key={index}>
                                                {
                                                    renderBubble(item, bubble.content)
                                                }
                                            </Fragment>

                                        })
                                    }
                                </div>

                            })

                        } </div>
                </div>
            }
            {
                (open === true && limit > 0) &&

                <div className='pagination'>
                    <div className='count'><b>{page + 1}</b> / {Math.ceil(count / limit)}</div>
                    <div className='controls'>
                        {
                            page > 0 && <div className='button back' onClick={() => {

                                setPage(page - 1)
                                getOptions(search, page - 1)

                            }}>
                                <i className='icon las la-angle-left' />
                            </div>
                        }

                        {
                            ((page + 1) * limit) < count && <div className='button next' onClick={() => {

                                setPage(page + 1)
                                getOptions(search, page + 1)

                            }}>
                                <i className='icon las la-angle-right' />
                            </div>
                        }

                    </div>
                </div>
            }

        </div >

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(LayoutFormSelectComponent)
