import './index.scss';
interface DivisorComponentProps {
    text: string,
    icon?: string,
    styles?: any
}
function LayoutDivisorComponent(props: DivisorComponentProps) {

    return (

        <div className='LayoutDivisorComponent' style={props.styles}>
            {props.icon && <i className={props.icon}></i>}
            {props.text && <div className="text">{props.text}</div>}
        </div>

    );

}
export default LayoutDivisorComponent
