import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Application from './app/app';
import reportWebVitals from './reportWebVitals';

import "./index.css"

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Application />
  </BrowserRouter>
);
