import moment from 'moment';
import './header.ticket.styles.scss';
interface HeaderTicketProperties {
    barcode: string,
    companyName: string,
    companyEmail: string,
    companyPhone: string,
    companyAddress: string,
    date: string,
}
export default function HeaderTicketComponent(settings: HeaderTicketProperties) {

    // date with moment local
    const date = moment(settings.date).format('DD/MM/YYYY HH:mm:ss');

    return <div className="HeaderTicketComponent">
        <div className='barcode'>
            {settings.barcode}
        </div>
        <div className='companyName'>
            {settings.companyName}
        </div>
        <div className='companyAddress'>
            {settings.companyAddress}
        </div>
        <div className='companyEmail'>
            {settings.companyEmail}
        </div>
        <div className='companyPhone'>
            {settings.companyPhone}
        </div>
        <div className='date'>
            {date}
        </div>

    </div>

}