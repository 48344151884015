import { Replace } from '../../../utilities/replace/replace.utility';
import './index.scss';
interface IconComponentProps {
    icon?: string,
    styles?: any,
    item?: any,
}
function IconComponent(props: IconComponentProps) {

    // Replace 
    const icon = props.item && props.icon ? Replace({ item: props.item }, props.icon) : props.icon;
    return (

        <div className='IconComponent' style={props.styles}>
            {icon && <i className={icon}></i>}
        </div>

    );

}
export default IconComponent
