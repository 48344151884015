import './index.scss';
import metadata from './../../../../metadata.json';
interface LayoutVersionProps {
  name: string;
  website: string;
  styles: any;
}
function LayoutVersionComponent(props: LayoutVersionProps) {

  const year = new Date().getFullYear();

  return (
    <div className="LayoutVersionComponent" style={props.styles}>
      <div className="title">
        <div className="name">{props.name}</div>
        <div className="legal">
          &copy; {year} {props.website}
        </div>
      </div>
      <div className="version">
        <div className="prefix">V.</div>
        <div className="number">{`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}</div>
        <div className="type">{metadata.buildTag}</div>
      </div>
    </div>
  );

}
export default LayoutVersionComponent;
