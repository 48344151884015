
// Function login props redux connect
import { Replace } from '../../../../../utilities/replace/replace.utility';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import './index.scss';

interface DispatchProps {
    name: string,
    type: 'text' | 'password' | 'hidden'
    store: any
    placeholder: string,
    disabled?: boolean,
    icon?: string,
    setData: (s: any) => void
    onSubmit: () => void
    defaultValue?: string,
}
function LayoutFormColorComponent(props: DispatchProps) {

    const [defaultValue, setDefaultValue] = useState<string>('#435bd1');
    const paramsUlr = useParams()

    useEffect(() => {

        if (props.defaultValue) {

            const defaultValue = Replace({ param: paramsUlr, params: paramsUlr, store: props.store }, props.defaultValue);
            setDefaultValue(defaultValue);
            props.setData(defaultValue);

        }

    }, [])
    return (
        <div className='LayoutFormColorComponent' style={{ display: props.type === 'hidden' ? 'none' : undefined }}>
            <div className={props.icon ? 'withIcon' : 'withoutIcon'}>
                {props.icon && <i className={`icon las ${props.icon}`} />}
                <input
                    type={props.type}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    onChange={(event) => {

                        setDefaultValue(event.target.value);
                        props.setData(event.target.value)

                    }}
                    onKeyUp={(event) => {

                        if (event.key === 'Enter') {

                            props.onSubmit()

                        }
                        else {

                            props.setData(event.currentTarget.value)

                        }

                    }}
                    value={defaultValue}
                />
                {
                    props.disabled && <i className='disabled las la-lock' />
                }
            </div>
        </div>

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(LayoutFormColorComponent)
