import { Replace } from '../../../utilities/replace/replace.utility';
import './index.scss';
interface TextComponentProps {
    text: string,
    icon?: string,
    styles?: any,
    item?: any,
}
function LayoutTextComponent(props: TextComponentProps) {

    // Replace 
    const text = props.item ? Replace({ item: props.item }, props.text) : props.text;
    return (

        <div className='LayoutTextComponent' style={props.styles}>
            {props.icon && <i className={props.icon}></i>}
            {text}
        </div>

    );

}
export default LayoutTextComponent
