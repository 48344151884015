import Stylize from '../../../stylize';
import LayoutScreenComponent from '../screen';
import './index.scss';

interface DivisorComponentProps {
    components: any,
    icon?: string,
    styles?: any,
    item?: any
}
function LayoutBoxComponent(props: DivisorComponentProps) {

    return <Stylize className={`LayoutBoxComponent`} style={props?.styles}>
        <LayoutScreenComponent components={props?.components} item={props.item} />
    </Stylize>

}

export default LayoutBoxComponent
