import './index.scss';
interface DivisorComponentProps {
    title: string,
    description: string,
    icon?: string,
    styles?: any
}
function LayoutBreadcrumbComponent(props: DivisorComponentProps) {

    return (

        <div className='LayoutBreadcrumbComponent' style={props.styles}>
            {props.icon && <div className={`icon ${props.icon}`} />}
            <div className='text'>
                <div className='title'>{props.title}</div>
                <div className='description'>{props.description}</div>
            </div>
        </div>

    );

}
export default LayoutBreadcrumbComponent
