import { CLEAR, SET_ROUTE, SET_SCREEN, SET_STORE } from '../constants/actions';

const initialState = {
  user: false,
  scheme: null,
};

function rootReducer(state = initialState, action) {

  if (action.type === SET_STORE) {

    let isDelete = false;
    for (const key in action.payload) {

      if (action.payload[key].remove) {

        isDelete = true;

      }

    }
    if (isDelete) {

      // Remove keys from store of payload
      const newData = { ...state.store };
      for (const key in action.payload) {

        delete newData[key];

      }
      return Object.assign({}, state, {
        store: newData,
      });

    }
    return Object.assign({}, state, {
      store: {
        ...state.store,
        ...action.payload,
      },
    });

  }

  if (action.type === SET_SCREEN) {

    return Object.assign({}, state, {
      screen: action.payload,
    });

  }

  if (action.type === SET_ROUTE) {

    return Object.assign({}, state, {
      route: action.payload,
    });

  }
  if (action.type === CLEAR) {

    return {};

  }

  return state;

}

export default rootReducer;
