
// Function login props redux connect
import Button, { ButtonComponentProps } from '../../../button';
import './index.scss';

interface DispatchProps {
    value?: string,
    onSubmit: () => void
    buttons?: ButtonComponentProps[]
}

export default function SubmitComponent(props: DispatchProps) {

    return (
        <div className='LayoutFormSubmitComponent'>
            {props.buttons && <div className='buttons'>
                {props.buttons && props.buttons.map((button, index) => {

                    return (
                        <Button key={index} {...button} />
                    );

                })}
            </div>}
            <button type='submit' onClick={(event) => {

                event.preventDefault();
                props.onSubmit();

            }}>
                {props.value || 'Enviar'}
                <i className='las la-arrow-right' />
            </button>

        </div>

    );

}

