import { useState } from "react";
import './accordion.styles.scss';
export default function AccordionComponent(props: { onDelete: any, title: string, children: React.ReactNode }) {

    const [active, setActive] = useState(false);

    return (
        <div className="accordionComponent">
            <div className="accordion__header" onClick={() => setActive(!active)}
            >
                {props.title}
                <span>
                    {
                        props.onDelete && <i className="las la-trash-alt" onClick={(e) => {

                            e.stopPropagation();
                            props.onDelete()

                        }} />
                    }
                    <i className={!active ? 'las la-angle-down' : 'las la-angle-up'} />
                </span>
            </div>
            <div className="accordion__body" style={{ display: active ? 'flex' : 'none' }}>
                {props.children}
            </div>
        </div>
    );

}