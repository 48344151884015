
import useMeasure from "react-use-measure"
import { useEffect, useRef, useState } from 'react';
import background from './background.jpg';
import './index.scss';
import SignatureCanvas from 'react-signature-canvas'

export default function LayoutFormSignatureComponent(props: any) {

    // UseRef
    const signCanvas = useRef() as React.MutableRefObject<any>;
    const [ref, { width }] = useMeasure();
    const [image, setImage] = useState<string | undefined>();
    const [haveSignature, setHaveSignature] = useState<boolean | undefined>();
    useEffect(() => {

        if (props.defaultValue) {

            setImage(props.defaultValue);

        }

    }, [])
    return (
        <div ref={ref} className='LayoutFormSignatureComponent' >
            <div className="background" style={{
                backgroundImage: `url(${background})`
            }} />
            {
                haveSignature && <i className="clean las la-times" onClick={() => {

                    props.setData(null);
                    setImage(undefined);
                    setHaveSignature(false);
                    const data = signCanvas.current.clear()

                }} />

            }
            {
                image && <div className="image" style={{ width: width, height: width / 2, }}>
                    <i className="las la-times" onClick={() => {

                        props.setData(null);
                        setImage(undefined);

                    }} />

                    <img alt="signature" src={`${image}`} style={{ width: width, height: width / 2, }} />
                </div>
            }
            <SignatureCanvas penColor='black'
                ref={signCanvas}
                onEnd={() => {

                    if (signCanvas) {

                        const data = signCanvas.current.getCanvas().toDataURL("image/png")
                        props.setData(data);
                        setHaveSignature(true);

                    }

                }
                }
                canvasProps={{ width: width, height: width / 2, className: 'sigCanvas' }} />
        </div>

    );

}

