
// Function login props redux connect
import { Replace } from '../../../../../utilities/replace/replace.utility';
import { useEffect, useState } from 'react';
import './index.scss';
import { useParams } from 'react-router-dom';

interface DispatchProps {
    name: string,
    placeholder: string,
    setData: (s: any) => void
    defaultValue?: string,
    disabled?: boolean,
}

export default function LayoutFormTextareaComponent(props: DispatchProps) {

    const paramsUlr = useParams()
    const [defaultValue, setDefaultValue] = useState<string>('');

    useEffect(() => {

        if (props.defaultValue) {

            const defaultValue = Replace({ param: paramsUlr }, props.defaultValue);
            setDefaultValue(defaultValue);
            props.setData(defaultValue);

        }

    }, [])
    return (
        <div className='LayoutFormTextareaComponent'>
            <textarea
                disabled={props.disabled}
                className='textarea'
                name={props.name}
                placeholder={props.placeholder}
                onChange={(event) => {

                    console.log('val:', event.target.value)
                    props.setData(event.target.value)
                    setDefaultValue(event.target.value)

                }}
                value={defaultValue}
            />
        </div>

    );

}

