import { useEffect, useState } from 'react';
import './search.wifly.styles.scss';

interface ListProperties {
  onFocus?: (value: string) => void;
  onChange: (query: string) => void;
  placeholder: string;
}
function normalizeString(str: string): string {
  return str
      .normalize("NFD") // Normalize to decomposed form
      .replace(/[\u0300-\u036f]/g, "") // Remove combining diacritical marks
}

export default function WiflySearchComponent(props: ListProperties) {
  const [userValue, setUserValue] = useState('');
  const [hasTildes, setHasTildes] = useState(false);

  const handleOnChange = () => {
    setHasTildes(!hasTildes);
  };

  // React to changes in hasTildes and userValue, re-applying normalization if needed
  useEffect(() => {
    const normalizedValue = hasTildes ? userValue : normalizeString(userValue);
    props.onChange(normalizedValue);
  }, [hasTildes]); // Depend on hasTildes to trigger normalization

  return (
    <>
      <div className="WiflySearchComponent">
        <i className="lent las la-search" />
        <input
          type={'text'}
          value={userValue}
          onFocus={() => {
            props.onFocus?.(userValue);
          }}
          onChange={(event) => {
            setUserValue(event.target.value);
            props.onChange(hasTildes ? event.target.value : normalizeString(event.target.value));
          }}
          placeholder={props.placeholder}
        />
        <div className="flex items-center space-x-2 mr-2">
          <input
              type="checkbox"
              id="tildesCheckbox"
              checked={hasTildes}
              onChange={handleOnChange}
              className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-150 ease-in-out hover:text-blue-700"
          />
          <label htmlFor="tildesCheckbox" className="text-sm font-medium text-gray-700">
              Tildes
          </label>
        </div>
      </div>
    </>
  );
}
