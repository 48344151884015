
// Function login props redux connect
import RequestAction from '../../../../../actions/request/request.action';
import { Replace } from '../../../../../utilities/replace/replace.utility';
import { getProperty } from 'dot-prop';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Compressor from 'compressorjs';

import './index.scss';

interface DispatchProps {
    name: string,
    type: 'google-storage'
    placeholder: string,
    store: any,
    endpoint?: string,
    bucket: string,
    mode?: string,
    icon?: string,
    setData: (s: any) => void
    defaultValue?: string,
    compress?: {
        width?: number,
        height?: number,
        quality?: number
    }
}

function FormBase64Component(props: DispatchProps) {

    const inputRef = useRef<HTMLInputElement>(null);
    const [defaultValue, setDefaultValue] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [path, setPath] = useState<string>('');
    const [cache, setCache] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);
    const paramsUlr = useParams()

    useEffect(() => {

        if (props.defaultValue) {

            const defaultValue = Replace({ param: paramsUlr }, props.defaultValue);
            setDefaultValue(defaultValue);
            props.setData(defaultValue);

        }

    }, [])
    return (
        <div className='LayoutFormInputComponent'>
            {
                defaultValue ? <div className='image'>
                    {
                        props.mode === 'avatar' ? <div className='avatar' style={{
                            backgroundImage: `url(${defaultValue})`
                        }}>
                            <i className='la la-times' onClick={() => {

                                props.setData('');
                                setDefaultValue('');

                            }} />
                        </div> : <>
                            <i className='la la-times' onClick={() => {

                                props.setData('');
                                setDefaultValue('');

                            }} />
                            <img src={`${defaultValue}`} />
                        </>
                    }

                </div> : <>

                    {
                        percentage === 100 ? null : <>

                            <div className='input' onClick={() => {

                                inputRef?.current?.click();

                            }}  >
                                <i className='icon las la-cloud-upload-alt'></i>

                                {
                                    percentage ? <div className='percentage'>{percentage}%</div> : 'Selecciona una imagen'
                                }
                            </div>
                            <div className='finder'>

                                <input type="file"
                                    accept='image/*'
                                    ref={inputRef}
                                    onChange={(e: any) => {

                                        const that: any = e.target;
                                        that.parentNode.classList.add('finder-loading');
                                        const image = that.files[0];
                                        new Compressor(image, {
                                            quality: props?.compress?.quality || 0.8,
                                            maxWidth: props?.compress?.width || 800,
                                            maxHeight: props?.compress?.height || 800,
                                            mimeType: 'image/jpeg',
                                            success: (compressedResult) => {

                                                // convert in base64
                                                const reader = new FileReader();
                                                reader.readAsDataURL(compressedResult);
                                                reader.onloadend = () => {

                                                    const base64data = reader.result;
                                                    setDefaultValue(base64data as string);
                                                    props.setData(base64data);

                                                }

                                            },
                                        });

                                    }

                                    } />

                            </div> </>}</>}
        </div>

    );

}

const mapStateToProps = (state: any, ownProps: any) => (state)

export default connect(mapStateToProps,)(FormBase64Component)
