
import './stripe.styles.scss';
interface StripeProperties {
    client?: string,
    name: string,
    setData: (s: any) => void

}

export default function StripeComponent(properties: StripeProperties) {

    return <></>

};
