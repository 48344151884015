
// Function login props redux connect
import { useEffect, useRef } from 'react';
import './index.scss';

interface DispatchProps {
    name: string,
    errors?: any,
    scrollError?: boolean,
    parent?: number
}

export default function LayoutFormErrorComponent(props: DispatchProps) {

    // Filter errors by
    const myRef = useRef<any>(null)

    const executeScroll = () => {

        if (props.parent === 0) {

            myRef?.current?.parentNode.scrollIntoView?.({ block: "start", behavior: "smooth", });

        }
        else if (props.parent === 1) {

            myRef?.current?.parentNode.scrollIntoView?.({ block: "start", behavior: "smooth", });

        }
        else if (props.parent === 2) {

            myRef?.current?.parentNode.parentNode.scrollIntoView?.({ block: "start", behavior: "smooth", });

        }

    }
    // Run this function from an event handler or an effect to execute scroll 

    useEffect(() => {

        if (props.errors) {

            props.scrollError && executeScroll();

        }

    }, [props.errors])
    if (props.errors?.[props.name]) {

        return (
            <div className='LayoutFormErrorComponent' ref={myRef}>
                <div className='items'>
                    {
                        props.errors?.[props.name]?.map((error: any, index: number) => {

                            return <div className='item' key={index}>
                                <i className='las la-exclamation-circle' />  {error}
                            </div>

                        })
                    }
                </div>
            </div>
        );

    }
    return null

}