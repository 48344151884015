import './button.filter.wifly.style.scss';

import { setRoute, setStore } from '../../../../store/actions';
import { connect } from 'react-redux';
import ButtonSearchWiflyComponent from './button-search/button.search.wifly.component';
import ButtonFilterOrder from './button-order/button.order.wifly.component';
import { useRef } from 'react';

const mapDispatchToProps = {
  setStore,
  setRoute,
};
const mapStateToProps = (state: any, ownProps: any) => state;

export interface ButtonFilterComponentProps {
  setRoute: (s: any) => void;
  setStore: (s: any) => void;
  store: any;
  text?: string;
  icon?: string;
  label?: string;
  tableItems?: any;
  id?: string;
  distinctSearchId?: string;
  count?: number;
  onApplyFilters: (key: string, values: string[]) => void;
  activeOrder?: any[];
  onApplyOrder: (key: string, value: string) => void;
  activeFilters: any[];
  children?: JSX.Element | JSX.Element[];
  bubble?: string;
  route?: string;
  type?: string;
  filterType?:
    | 'search'
    | 'order'
    | 'searchOrder';
  item?: any;
  virgin?: boolean;
  title?: string;
  path?: string;
  tooltip?: string;
  actions: any;
  styles?: any;
  content?: any;
  development?: {
    status?: 'complete';
  };
  onPress?: () => void;
}

type Props = ButtonFilterComponentProps;

function ButtonFilterWiflyComponent(props: Props) {
 const filterType = props.filterType;

  return (
    <>
      { filterType === 'search' && (<div className='button'>
      
      <div className="text-left pl-0 pr-2">{props.text}</div>
      <ButtonSearchWiflyComponent
        path={props.path}
        text={props.text}
        label={props.label}
        content={props.content}
        tableItems={props.tableItems}
        type={props.type}
        id={props.id}
        distinctSearchId={props.distinctSearchId}
        count={props.count}
        onApplyFilters={props.onApplyFilters}
        activeFilters={props.activeFilters}
      />
      </div>)
      }
      {filterType === 'order' && (<div className='button'>
      
      <div className="text-left pl-0 pr-2">{props.text}</div>
      <ButtonFilterOrder
        path={props.path}
        text={props.text}
        label={props.label}
        id={props.id}
        count={props.count}
        onApplyOrder={props.onApplyOrder}
        activeOrder={props.activeOrder}
      />
      </div>)
      }
      {filterType === 'searchOrder' && (<div className='button'>
      
      <div className="text-left pl-0 pr-2">{props.text}</div>
      <ButtonSearchWiflyComponent
        path={props.path}
        text={props.text}
        label={props.label}
        type={props.type}
        tableItems={props.tableItems}
        content={props.content}
        distinctSearchId={props.distinctSearchId}
        id={props.id}
        count={props.count}
        onApplyFilters={props.onApplyFilters}
        activeFilters={props.activeFilters}
      />
      <ButtonFilterOrder
        path={props.path}
        text={props.text}
        label={props.label}
        id={props.id}
        count={props.count}
        onApplyOrder={props.onApplyOrder}
        activeOrder={props.activeOrder}
      />
      </div>)
      }
     {/*ORDER BY INACTIVE*/}
     {/*<ButtonFilterOrder text={props.text} />*/}
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonFilterWiflyComponent);
