import { Replace } from '../../../utilities/replace/replace.utility';

export const buttonFilter = (
  path: any,
  items: any,
  label: any,
  content: any
) => {
  if (!items || Object.keys(items).length < 1) return path;
  let where: any = {};
  where['_or'] = {};
  where._or['_or'] = [];
  items.forEach((item: any) => {
    let id = getValueFromPath(item, 'id', content);
    where._or._or.push({ [label]: { _eq: `${id}` } });
  });
  const string = JSON.stringify(where);
  const encoded = encodeURIComponent(string);
  path += `&filters=${encoded}`;
  return path;
};

export const getValueFromPath = (
  obj: any,
  path: string | string[],
  content: any
) => {
  if (!Array.isArray(path)) {
    path = path.replace(/{|item\.|}/g, '');
    let result = path.split('.').reduce((acc, part) => acc && acc[part], obj);
    //for bools
    if (content && content.length > 0) {
      if (content[0].type && content[0].type === 'boolean') {
        result = result ? '✓' : 'x';
      } else if (content[0].conditions && content[0].conditions[0]) {
        result = result === null ? content[0].text : result;
      }
    }
    return result;
  }

  let result: string = '';
  path.forEach((val: string) => {
    result += Replace({ item: obj }, val) + ' ';
  });
  return result;
};

export const getUniqueItems = (
  items: any[],
  label: any,
  content: any
): any[] => {
  const uniqueItemsMap = new Map();
  if (!Array.isArray(label)) {
    items.forEach((item) => {
      const value = getValueFromPath(item, label, content);
      if (value !== undefined && !uniqueItemsMap.has(value)) {
        uniqueItemsMap.set(value, item);
      }
    });
  } else {
    items.forEach((item) => {
      let value: string = '';
      label.forEach((val: string) => {
        val = val.replace(/{|item\.|}/g, '');
        value += getValueFromPath(item, val, content);
      });
      if (value !== undefined && !uniqueItemsMap.has(value)) {
        uniqueItemsMap.set(value, item);
      }
    });
  }
  return Array.from(uniqueItemsMap.values());
};

export const distinctToPath = (
  path: string,
  distinctSearchId: any,
  content: any
) => {
  let payload: any = [];
  if (distinctSearchId != undefined) {
    payload.push({ [distinctSearchId]: 'asc' });
  } else if (content && content.length > 0) {
    content.forEach((c: any, index: any) => {
      if (content[index].id) {
        let parts: string[] = content[index].id.split('.');
        let distinctId =
          parts.length > 1 ? `${parts[index]}_id` : `${parts[0]}`;
        payload.push({ [distinctId]: 'asc' });
      } else if (
        content[index].conditions &&
        content[index].conditions.length > 0
      ) {
        let parts: string[] = content[index].conditions[index].id.split('.');
        let distinctId = parts.length > 1 ? `${parts[0]}_id` : `${parts[0]}`;
        payload.push({ [distinctId]: 'asc' });
      }
    });
  }
  let distinctSerialized = JSON.stringify({ payload });
  if (distinctSerialized !== '{}') {
    let distinctEncoded = encodeURIComponent(distinctSerialized);
    path += `&distinct=${distinctEncoded}`;
  }
  return path;
};

export const filtersToPath = (path: string, search: string, props: any) => {
  if (search) {
    const keys: string | string[] = props.label;
    let payload: any = {};
    if (!Array.isArray(keys)) {
      let query: any = {};
      const key = keys.replace(/{|item\.|}/g, '');
      const parts: string[] = key.split('.');
      parts.forEach((part, index) => {
        let content = query;
        if (index === parts.length - 1) {
            if (index === 0)
                content[part] = { _ilike: `%${search}%` } ;
            else
                content[parts[index - 1]] = { [part]: { _ilike: `%${search}%` } };
        } else {
          if (index === 0) content = { [part]: {} };
          else content[index - 1] = { [part]: {} };
        }
      });
      payload = { _and: query };
    } else {
      const query = buildQuery(keys, search);
      payload = { _and: query };
    }
    const json = JSON.stringify(payload);
    path = `${path}&filters=${encodeURIComponent(json)}`;
  }
  return path;
};

const buildQuery = (keys: any, search: any) => {
  let baseQuery = {};

  keys.forEach((key: any) => {
    key = key.replace(/{|item\.|}/g, ''); // Clean the key
    const parts = key.split('.');
    let current: any = baseQuery;

    parts.forEach((part: any, index: any) => {
      if (index === parts.length - 1) {
        const condition = { [part]: { _ilike: `%${search}%` } };

        if (!current['_or']) {
          current['_or'] = [condition];
        } else {
          current['_or'].push(condition);
        }
      } else {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    });
  });

  const convertToAndOrStructure = ((obj: any) => {
    const keys = Object.keys(obj);
    if (keys.length > 1 || keys.some((key) => key === '_or')) {
      const andArray: any = keys
        .filter((key) => key !== '_or')
        .map((key) => ({
          [key]: convertToAndOrStructure(obj[key]),
        }));

      if (obj['_or']) {
        andArray.push({ _or: obj['_or'] });
      }

      return { _and: andArray };
    } else {
      return obj;
    }
  });

  return convertToAndOrStructure(baseQuery);
};
