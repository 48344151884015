import { useEffect, useState } from "react";

function getWindowDimensions() {

    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };

}

export default function Stylize(props: any) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {

        function handleResize() {

            setWindowDimensions(getWindowDimensions());

        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);
    // Detect phone
    const { width } = windowDimensions;
    const isMobile = width < 768;
    const isTablet = width < 1224 && width >= 768;
    const isDesktop = width >= 1224;

    const styles = props.style;
    let newStyles: any = {}
    const classMore: any = [props.className]
    if (styles) {

        // Replace styles that are objects:
        newStyles = Object.keys(styles).reduce((acxx: any, styleKey) => {

            if (styleKey === 'animations') {

                styles[styleKey].map((animation: any) => {

                    classMore.push(`Animation${animation.type.charAt(0).toUpperCase() + animation.type.slice(1)}`)
                    return true;

                })

            }
            else if (typeof styles[styleKey] === 'object') {

                let value = undefined
                if (isMobile && styles[styleKey].mobile) value = styles[styleKey].mobile;
                else if (isTablet && styles[styleKey].tablet) value = styles[styleKey].tablet;
                else if (isDesktop && styles[styleKey].desktop) value = styles[styleKey].desktop;
                else if (styles[styleKey].default) value = styles[styleKey].default;
                acxx[styleKey] = value

            }
            else {

                acxx[styleKey] = styles[styleKey]

            }
            return acxx;

        }, {});

    }
    if (newStyles.display === 'none') return null;
    return <div ref={props?.refOutside} className={classMore.join(' ')} style={newStyles}>
        {
            props.children
        }
    </div>

}
