import {
  Fragment,
  FC,
  useState,
  useRef,
  MouseEventHandler,
  useEffect,
} from 'react';
import { Popover, Transition } from '@headlessui/react';
import { setRoute, setStore } from '../../../../../store/actions';
import { connect } from 'react-redux';
import button from '../../../../layout/button';

const mapDispatchToProps = {
  setStore,
  setRoute,
};
const mapStateToProps = (state: any, ownProps: any) => state;

export interface ButtonFilterOrderProps {
  setRoute: (s: any) => void;
  setStore: (s: any) => void;
  store: any;
  text?: string;
  icon?: string;
  children?: JSX.Element | JSX.Element[];
  bubble?: string;
  route?: string;
  item?: any;
  virgin?: boolean;
  title?: string;
  path?: string;
  tooltip?: string;
  actions: any;
  styles?: any;
  activeOrder?: string;
  onApplyOrder: (key: string, value: string) => void;
  development?: {
    status?: 'complete';
  };
  onPress?: () => void;
}

type Props = ButtonFilterOrderProps;

const ButtonFilterOrder = (props: Props) => {
  const [sortOrder, setSortOrder] = useState<string>(props.activeOrder || '');
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleChange = (e: any, val: string) => {
    const value = val as 'ASC' | 'DESC';
    setSortOrder((currentSortOrder) =>
      currentSortOrder === value ? '' : value
    );
  };

  function clearSelection(): void {
    setSortOrder('');
  }

  function handleApply() {
    props.onApplyOrder(props.text || '', sortOrder || '');
    clearSelection();
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }

  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="inline-flex items-center gap-x-1 text-xs font-medium leading-none text-gray-900 py-2 px-2 hover:bg-gray-200"
      >
        <div className="la la-filter" />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left0 -translate-x-40 z-50 mt-2 flex w-screen max-w-xs px-4">
          <div className=" rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-black ring-opacity-5">
            {sortOrder !== '' && (
              <div className="bg-green-100 text-green-800 text-xs font-semibold px-1.5 py-0.5 rounded flex items-center w-32">
                {`Ordenado: ${sortOrder}`}
                <button
                  onClick={() => clearSelection()}
                  className="ml-2 rounded-full p-0.5 inline-flex items-center justify-center text-green-500 hover:text-red-500 focus:outline-none"
                >
                  &times;
                </button>
              </div>
            )}
            <div className="p-4" style={{ minWidth: '180px' }}>
              <b>{`Ordenar ${props.text}`}</b>
              <form>
                <ul className="divide-y divide-gray-200">
                  <li
                    className="flex items-center py-2 px-2 bg-gray-100"
                    onClick={(e) => handleChange(e, 'ASC')}
                  >
                    <input
                      id="ascendente"
                      type="checkbox"
                      value="ASC"
                      checked={sortOrder === 'ASC'}
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="ascendente"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      ASC
                    </label>
                  </li>
                  <li
                    className="flex items-center py-2 px-2 bg-gray-100"
                    onClick={(e) => handleChange(e, 'DESC')}
                  >
                    <input
                      id="descendente"
                      type="checkbox"
                      value="DESC"
                      checked={sortOrder === 'DESC'}
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="descendente"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      DESC
                    </label>
                  </li>
                </ul>
              </form>
            </div>
            <div className="flex justify-between mt-4 px-4">
              <button
                onClick={() => {
                  handleApply();
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Apply
              </button>
              <button
                onClick={clearSelection}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Clear
              </button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonFilterOrder);
