
import './index.scss';
interface DivisorComponentProps {
    title: string,
    description: string,
    icon?: string,
    close?: {
        path: string
    },
    styles?: any
}
function LayoutControlComponent(props: DivisorComponentProps) {

    return (

        <div className='LayoutControlComponent' style={props.styles}>
            Logotype
        </div>

    );

}
export default LayoutControlComponent
