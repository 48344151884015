import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';
import { useState } from 'react';
import { ControlPosition, MarkerProps, useControl } from 'react-map-gl';

type GeocoderControlProps = Omit<GeocoderOptions, 'accessToken' | 'mapboxgl' | 'marker'> & {
    mapboxAccessToken: string;
    marker?: boolean | Omit<MarkerProps, 'longitude' | 'latitude'>;

    position: ControlPosition;

    onLoading?: (e: object) => void;
    onResults?: (e: object) => void;
    onResult?: (e: object) => void;
    onError?: (e: object) => void;
};

/* eslint-disable complexity,max-statements */
export default function GeocoderControl(props: GeocoderControlProps) {

    const [marker, setMarker] = useState(null);

    const geocoder = useControl<MapboxGeocoder>(
        () => {

            const ctrl = new MapboxGeocoder({
                ...props,
                marker: false,
                accessToken: props.mapboxAccessToken
            });
            ctrl.on('result', (evt: any) => {

                props.onResult && props.onResult(evt);

            });
            return ctrl;

        },
        {
            position: props.position
        }
    );

    // @ts-ignore (TS2339) private member
    if (geocoder._map) {

        if (geocoder.getProximity() !== props.proximity && props.proximity !== undefined) {

            geocoder.setProximity(props.proximity);

        }
        if (geocoder.getRenderFunction() !== props.render && props.render !== undefined) {

            geocoder.setRenderFunction(props.render);

        }
        if (geocoder.getLanguage() !== props.language && props.language !== undefined) {

            geocoder.setLanguage(props.language);

        }
        if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {

            geocoder.setZoom(props.zoom);

        }
        if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {

            geocoder.setFlyTo(props.flyTo);

        }
        if (geocoder.getPlaceholder() !== props.placeholder && props.placeholder !== undefined) {

            geocoder.setPlaceholder(props.placeholder);

        }
        if (geocoder.getCountries() !== props.countries && props.countries !== undefined) {

            geocoder.setCountries(props.countries);

        }
        if (geocoder.getTypes() !== props.types && props.types !== undefined) {

            geocoder.setTypes(props.types);

        }
        if (geocoder.getMinLength() !== props.minLength && props.minLength !== undefined) {

            geocoder.setMinLength(props.minLength);

        }
        if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {

            geocoder.setLimit(props.limit);

        }
        if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {

            geocoder.setFilter(props.filter);

        }
        if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {

            geocoder.setOrigin(props.origin);

        }

    }
    return marker;

}

const noop = () => { };

GeocoderControl.defaultProps = {
    marker: true,
    onLoading: noop,
    onResults: noop,
    onResult: noop,
    onError: noop
};