import { Fragment, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { setRoute, setStore } from '../../../../../store/actions';
import { connect } from 'react-redux';
import WiflySelectComponent from '../../../select/select.wifly.component';

interface DetailsDTO {
  id: number;
  name: string;
  href: string;
}

const mapDispatchToProps = {
  setStore,
  setRoute,
};
const mapStateToProps = (state: any, ownProps: any) => state;

export interface ButtonFilterSearchProps {
  setRoute: (s: any) => void;
  setStore: (s: any) => void;
  store: any;
  text?: string;
  icon?: string;
  label?: string;
  tableItems?: any;
  type?: string;
  distinctSearchId?: string;
  id?: string;
  children?: JSX.Element | JSX.Element[];
  bubble?: string;
  route?: string;
  item?: any;
  count?: number;
  onApplyFilters: (activeFilters: any[]) => void;
  activeFilters: any[];
  virgin?: boolean;
  title?: string;
  path?: string;
  tooltip?: string;
  actions: any;
  content?: any;
  styles?: any;
  development?: {
    status?: 'complete';
  };
  onPress?: () => void;
}

type Props = ButtonFilterSearchProps;

const ButtonSearchWiflyComponent = (props: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onApplyCloseWindow = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  return (
    //Button to open search
    <Popover className="relative">
      <Popover.Button
        className="inline-flex 
                            items-center 
                            gap-x-1 
                            text-xs 
                            font-medium 
                            leading-none 
                            text-gray-900 
                            py-2 px-2 
                            hover:bg-gray-200"
        ref={buttonRef}
      >
        <div className="la la-search" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className="absolute 
                        left0 
                        -translate-x-40
                        left-7
                        z-50 
                        mt-2 
                        flex 
                        w-screen 
                        max-w-xs 
                        px-4"
        >
          <div
            className="w-screen 
                            max-w-sm 
                            flex-auto 
                            rounded-3xl 
                            bg-white 
                            p-4 
                            text-sm 
                            leading-6 
                            shadow-lg 
                            ring-1 
                            ring-black 
                            ring-opacity-5"
          >
            <WiflySelectComponent
              name={''}
              icon={'las la-bicycle'}
              path={props.path}
              id={props.id}
              type={props.type}
              tableItems={props.tableItems}
              label={props.label}
              content={props.content}
              distinctSearchId={props.distinctSearchId}
              bubble={'{item._status}'}
              placeholder={props.text}
              category={props.text}
              count={props.count}
              onApplyFilters={props.onApplyFilters}
              activeFilters={props.activeFilters}
              onApplyCloseWindow={onApplyCloseWindow}
            />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonSearchWiflyComponent);
