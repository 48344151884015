import './total.ticket.styles.scss'
interface TotalTicketProperties {
    total: number,
    footer: string,
    coin: string
}
export default function TotalTicketComponent(settings: TotalTicketProperties) {

    let total = settings.total
    if (typeof settings?.total === 'string') {

        total = parseFloat(settings.total)

    }
    return <div className="TotalTicketComponent">
        <div className="tax">* IVA incluido</div>
        <div className="total">
            Total <b>{total?.toFixed?.(2)}{settings.coin}</b>
        </div>
        <div className='footer'>
            {settings.footer}
        </div>
    </div>

}